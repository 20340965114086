import React, { useState } from 'react'
import styled from 'styled-components'
import { withTheme } from 'styled-components'
import { Link } from 'gatsby'

import { ContainerBoxedRelative } from './../Layout/Grid'
import SignageImage from '../SignageImage/SignageImage'
import MainSlider, { SliderProps } from '../Slider/MainSlider'
import { StoryblokBase, StoryblokComponentProps, StoryblokImage, StoryblokMetaFields } from '../../types'
import { Chevron, LocationHeadline } from '../Icons'
import IconGrid, { IconGridProps } from '../IconGrid/IconGrid'
import { OutlineButtonStyle } from '../Buttons/OutlineButton'
import { mediaQueries, ThemeType } from '../Layout/Themes'
import Components from '../Components'
import { LocationHeadlineIcon, LocationHeadlineStyle } from '../FeatureHeadline/FeatureHeadline'
import { Helmet } from 'react-helmet'

interface Oeffnungszeiten extends StoryblokBase {
    wochenTage: Oeffnungszeit[],
    feierTage: Oeffnungszeit[]
}

interface Oeffnungszeit extends StoryblokBase {
    tag: string,
    beginn: string,
    ende: string
}

interface Adresse extends StoryblokBase {
    plz: string,
    strasse: string,
    ort: string,
    email: string,
    telefon: string
}

export interface FilialeProps extends StoryblokBase {
    headerSlider: SliderProps,
    previewImage: StoryblokImage,
    title: string,
    lat?: number,
    lng?: number,
    typ: string,
    heading: string,
    subHeading: string,
    slider: SliderProps,
    adresse: Adresse[],
    oeffnungsZeiten: Oeffnungszeiten[],
    iconGrid: IconGridProps,
    content: StoryblokBase[],
    seo?: StoryblokMetaFields
}

const Grid = styled.div`
    display: grid;
    margin-top: 48px;
    grid-column: 2;
    z-index: 1;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 6rem;
    grid-auto-flow: row dense;
    padding: 0 12px;
`

const HeadingContainer = styled.div`
    margin: 4rem 0;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row: 1;
    white-space: pre-wrap;
    text-align: center;
    display: block;
    overflow: hidden;
`

const InformationContainer = styled.div`
    white-space: pre-wrap;
    display: block;
    overflow: hidden;

    h1,h2,h3 {
        text-transform: uppercase;
        margin-bottom: 0.5rem;
    }

    p {
        margin-top: 0;
        margin-bottom: 0.25rem;
    }

    table, td {
        vertical-align: bottom;
    }

    ${mediaQueries("Mobile")`
        grid-column-start: 1;
        grid-column-end: 3;
    `};

    ${mediaQueries("Tablet")`
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row: 2;
    `};
`

const SliderContainer = styled.div`
    white-space: pre-wrap;
    display: block;
    overflow: hidden;

    ${mediaQueries("Mobile")`
        grid-column-start: 1;
        grid-column-end: 3;
    `};

    ${mediaQueries("Tablet")`
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row: 2;
    `};
`

const IconContainer = styled.div`
    grid-column-start: 1;
    grid-column-end: 3;

    ${mediaQueries("Tablet")`
        grid-row: 3;
    `};
`

const AdditionalContentContainer = styled.div`
    grid-column-start: 1;
    grid-column-end: 3;

    ${mediaQueries("Tablet")`
        grid-row: 4;
    `};
`

const ButtonContainer = styled.div`
    display: grid;
    margin: 6rem 0;
    grid-column-start: 1;
    grid-column-end: 3;
    justify-items: center;

    ${mediaQueries("Tablet")`
        grid-row: 5;
    `};
`

const Icon = styled(Chevron) <{ setRotate: boolean }>`
    margin-left: auto;
    transition: transform 0.3s ease;
    transform: ${({ setRotate }) => setRotate ? "rotate(180deg)" : "rotate(0deg)"};
    cursor: pointer;
    fill: ${({ theme }) => theme.colors.fonts.body};
`

const TableButton = styled.button`
    position: absolute;
    bottom: 0;
    left: 0;
    background: transparent;
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
`

const FilialTypMapper = (typ: string) => {
    switch (typ) {
        case "cafe":
            return "Café"
        case "erlebnisCafe":
            return "Café"
        case "baeckerei":
            return "Bäckereifiliale"
        case "markt":
            return "Marktfiliale"
        default:
            break;
    }
}

const MultipleFilialTypMapper = (typ: string) => {
    switch (typ) {
        case "cafe":
            return "Cafés"
        case "baeckerei":
            return "Bäckereifilialen"
        case "markt":
            return "Marktfilialen"
        default:
            break;
    }
}

const LocationButton = styled(OutlineButtonStyle)`
    svg {
        transition: all 0.30s linear;
    }

    :hover svg {
        fill: #000;
    }
`

const LocationButtonIcon = styled(LocationHeadline)`
    position: relative;
    bottom: -3px;
    padding-right: 8px;
`

interface FilialePageProps extends StoryblokComponentProps<FilialeProps> {
    theme: ThemeType
}

const Filiale = (props: FilialePageProps) => {
    const { blok } = props;
    const { seo } = blok;

    const [isHolidaysVisible, setIsHolidaysVisible] = useState(false);
    const [isRotate, setIsRotate] = useState(false);
    const adress = blok.adresse[0];
    const oeffnungszeiten = blok.oeffnungsZeiten[0];

    const toggleHoliday = () => {
        setIsRotate(!isRotate);
        setIsHolidaysVisible(!isHolidaysVisible);
    }

    return (
        <React.Fragment>
            <Helmet>
                {seo?.title && <title>{seo.title}</title>}
                {seo?.description && <meta name="description" content={seo.description} />}
            </Helmet>
            <MainSlider blok={blok.headerSlider[0]} />
            <ContainerBoxedRelative>
                <Grid>
                    <HeadingContainer>
                        <LocationHeadlineStyle size={1.5}>
                            <LocationHeadlineIcon /> {FilialTypMapper(blok.typ.toString())}
                        </LocationHeadlineStyle>
                        <h1>{blok.heading}</h1>
                        <p>{blok.subHeading}</p>
                    </HeadingContainer>
                    <SliderContainer>
                        <MainSlider blok={blok.slider[0]} />
                    </SliderContainer>
                    <InformationContainer>
                        {
                            adress && <React.Fragment>
                                <h2>Adresse</h2>
                                <p>{adress.strasse}</p>
                                <p>{adress.plz} {adress.ort}</p>
                                <p>Tel. {adress.telefon}</p>
                                <p>E-Mail {adress.email}</p>
                            </React.Fragment>
                        }
                        <br />
                        <br />
                        {
                            oeffnungszeiten && <React.Fragment>
                                <h2>Öffnungszeiten</h2>
                                <table>
                                    <tbody>
                                        {oeffnungszeiten.wochenTage.map((element, index) =>
                                            <tr key={"öffnungszeit-" + index}>
                                                <td width="100">
                                                    {element.tag}:
                                    </td>
                                                <td>
                                                    {element.beginn} - {element.ende}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td width="100px">Feiertage</td>
                                            <td style={{ position: "relative" }}>
                                                <TableButton aria-label="button" onClick={toggleHoliday}><Icon setRotate={isRotate} /></TableButton></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table style={{ display: isHolidaysVisible ? "block" : "none", transition: "0.6s ease" }}>
                                    <tbody>
                                        {oeffnungszeiten.feierTage.map((element, index) =>
                                            <tr key={"feiertag-" + index}>
                                                <td width="60%">
                                                    {element.tag}:
                                        </td>
                                                <td style={{ paddingLeft: "24px" }}>
                                                    {element.beginn} {element.ende && " - " + element.ende}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </React.Fragment>
                        }
                    </InformationContainer>
                    <IconContainer>
                        <IconGrid blok={blok.iconGrid[0]} />
                    </IconContainer>
                    <AdditionalContentContainer>
                        {props.blok.content && props.blok.content.map((blok) => React.createElement(Components(blok.component), { key: blok._uid, blok: blok, location: blok.component.location }))}
                    </AdditionalContentContainer>
                    <ButtonContainer>
                        <LocationButton>
                            <Link style={{ textTransform: "uppercase" }} to={"/standorte#" + blok.typ}>
                                ALLE {MultipleFilialTypMapper(blok.typ.toString())} <LocationButtonIcon height={18} color={props.theme.colors.colors.Beige} />
                            </Link>
                        </LocationButton>
                    </ButtonContainer>
                </Grid>
                <SignageImage />
            </ContainerBoxedRelative>
        </React.Fragment>
    )
}

export default withTheme(Filiale)