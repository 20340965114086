import { DefaultTheme } from "styled-components"

const DeviceSizes = {
    Desktop: "1440px",
    Laptop: "1025px",
    Tablet: "768px",
    Mobile: "20px"
};

export const mediaQueries = (key: keyof typeof DeviceSizes, props?: any) => {
    return (style: TemplateStringsArray | String) =>
        `@media (min-width: ${DeviceSizes[key]}) { ${style} }`;
};

type Devices = "Desktop" | "Laptop" | "Tablet" | "Mobile";

declare module 'styled-components' {
    export interface DefaultTheme {
        name: string,
        font: {
            body: {
                family: string;
                weight: { [key: string]: string; }
            },
            headings: {
                family: string,
                weight: { [key: string]: string; }
            }
        },
        colors: {
            colors: ThemeColors,
            fonts: { [key: string]: string; },
            backgrounds: { [key: string]: string; },
            slider: { [key: string]: string; },
            buttons: {
                outline: { [key: string]: string; }
            },
            footer: { [key: string]: string; },
            swipeMenu: { [key: string]: string; }
        },
        deviceSizes: {
            [key in Devices]: string;
        }
    }
}

export type ThemeType = typeof lightTheme

interface ThemeColors {
    Beige: string,
    Beige2: string,
    Black: string,
    White: string,
    Grey1: string,
    Grey2: string,
    Grey3: string,
    Grey4: string
}

export const ThemeNames = {
    Light: "light",
    Dark: "dark"
}

export const ThemeColors: ThemeColors = {
    Beige: "#c8b482",
    Beige2: "#f9f9f9",
    Black: "#000",
    White: "#FFF",
    Grey1: "#0d0d0d",
    Grey2: "#4c4c4c",
    Grey3: "#7f7f7f",
    Grey4: "#191919"
}

export const lightTheme: DefaultTheme = {
    name: ThemeNames.Light,
    font: {
        body: {
            family: "'Rajdhani', sans-serif",
            weight: {
                medium: "500",
                semiBold: "600"
            }
        },
        headings: {
            family: "'Khand', sans-serif;",
            weight: {
                regular: "400",
                medium: "500"
            }
        },
    },
    colors: {
        colors: ThemeColors,
        fonts: {
            body: ThemeColors.Black,
            navLink: ThemeColors.Black,
            navLinkHover: ThemeColors.Beige,
            socialIcons: ThemeColors.Grey2,
            socialIconsHover: ThemeColors.Grey3,
            copyright: ThemeColors.Grey2,
            white: ThemeColors.White,
            black: ThemeColors.Black,
            heading: ThemeColors.Black
        },
        backgrounds: {
            header: ThemeColors.White,
            body: ThemeColors.White,
            footer: ThemeColors.Black
        },
        slider: {
            sliderDots: ThemeColors.Beige,
            sliderDotsActive: ThemeColors.White,
            heading: ThemeColors.White
        },
        buttons: {
            outline: {
                border: ThemeColors.Beige,
                text: ThemeColors.Beige,
                textHover: ThemeColors.Black
            }
        }, footer: {
            navLink: ThemeColors.Beige
        },
        swipeMenu: {
            color: ThemeColors.Grey3,
            hover: ThemeColors.Grey2,
            selected: ThemeColors.Black,
            background: ThemeColors.Beige2
        }
    },
    deviceSizes: DeviceSizes
}

export const darkTheme: DefaultTheme = {
    name: ThemeNames.Dark,
    font: {
        body: {
            family: "'Rajdhani', sans-serif",
            weight: {
                medium: "500",
                semiBold: "600"
            }
        },
        headings: {
            family: "'Khand', sans-serif",
            weight: {
                regular: "400",
                medium: "500"
            }
        }
    },
    colors: {
        colors: ThemeColors,
        fonts: {
            body: ThemeColors.Beige,
            navLink: ThemeColors.White,
            navLinkHover: ThemeColors.Beige,
            socialIcons: ThemeColors.Grey2,
            socialIconsHover: ThemeColors.Grey3,
            copyright: ThemeColors.Grey2,
            white: ThemeColors.White,
            black: ThemeColors.Black,
            heading: ThemeColors.White
        },
        backgrounds: {
            header: ThemeColors.Black,
            body: ThemeColors.Grey1,
            footer: ThemeColors.Black
        },
        slider: {
            sliderDots: ThemeColors.Beige,
            sliderDotsActive: ThemeColors.White,
            heading: ThemeColors.White
        },
        buttons: {
            outline: {
                border: ThemeColors.Beige,
                text: ThemeColors.Beige,
                textHover: ThemeColors.Black
            }
        },
        footer: {
            navLink: ThemeColors.Beige
        },
        swipeMenu: {
            color: ThemeColors.Grey3,
            hover: ThemeColors.Grey2,
            selected: ThemeColors.Beige,
            background: ThemeColors.Grey4
        }
    },
    deviceSizes: DeviceSizes
}