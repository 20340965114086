import * as React from 'react'
import { useState } from 'react'
import styled, { css } from 'styled-components'
import Link from 'gatsby-link'

import { StoryblokBase, StoryblokComponentProps, StoryblokLink } from '../../types'
import { Chevron } from '../Icons'
import { mediaQueries } from './Themes'

const NavItemStyle = css`
    display: flex;
    font-size: 1.2rem;
    font-weight: ${({ theme }) => theme.font.headings.weight.regular};
    font-family: ${({ theme }) => theme.font.headings.family};
    text-transform: uppercase;
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.fonts.navLink};
    transition: all 0.30s linear;
    padding: 27px 8px;
    svg {
        display: none;
        transition: all 0.30s linear;
        padding-left: 4px;
        fill: ${({ theme }) => theme.colors.fonts.navLink};
    }
    &:hover {
        color: ${({ theme }) => theme.colors.fonts.navLinkHover};
        svg {
            fill: ${({ theme }) => theme.colors.fonts.navLinkHover};
        }
    }
    text-decoration: none;
    &.active {
        color: ${({ theme }) => theme.colors.fonts.navLinkHover};
        cursor: default;
    }
`

const NavItemLink = styled(Link)`
    ${NavItemStyle};
`

const AnchorLink = styled.a`
    ${NavItemStyle};
`

const Wrapper = styled.div`
    position: relative;
`

const DropDown = styled.div`
    position: absolute;
    padding: 8px 0;
    margin: 12px 0;
    border-radius: 10px;
    z-index: 10;
    top: 60px;
    display: grid;
    grid-auto-flow: row dense;
    box-shadow: ${({ theme }) => theme.name === 'light' ? "0 .5rem 1rem rgba(0, 0, 0, .8)" : "0 -.2rem 1rem rgba(46, 49, 49, .7)"};
    background: ${({ theme }) => theme.colors.backgrounds.header};
    align-items: center;
    &:after {
        content:'';
        position: absolute;
        top: -10px;
        left: 14px;
        width: 0;
        height: 0;
        border-left: solid 10px transparent;
        border-right: solid 10px transparent;
        border-bottom: solid 10px ${({ theme }) => theme.colors.backgrounds.header};
    }

    a {
        padding: 6px 24px;
    }

    ${mediaQueries("Mobile")`
        display: none;
    `};

    ${mediaQueries("Tablet")`
        display: block;
    `};
`

interface DropDownLink extends StoryblokBase {
    name: string,
    link: StoryblokLink,
}

export interface NavItemProps extends StoryblokBase {
    name: string,
    link: StoryblokLink,
    dropDown: DropDownLink[]
}

interface StoryblokComponentNavItemProps extends StoryblokComponentProps<NavItemProps> {
    location: Location,
    className?: string,
    onClick?: any
}

const NavItem = (props: StoryblokComponentNavItemProps) => {
    const [isDropDown, setIsDropDown] = useState(false)

    const { blok } = props;
    const url = blok.link.cached_url;

    return (
        <Wrapper
            onMouseEnter={() => setIsDropDown(true)}
            onMouseLeave={() => setIsDropDown(false)}>
            <NavItemLink
                className={props.className}
                onClick={props.onClick}
                activeClassName={"active"}
                partiallyActive={true}
                to={"/" + url}>
                {blok.name} {blok.dropDown && <Chevron />}
            </NavItemLink>
            {
                (isDropDown && blok.dropDown) &&
                <DropDown>
                    {blok.dropDown.map((item) => item.link.anchor ?
                        <AnchorLink
                            key={item._uid}
                            className={props.className}
                            href={"/" + item.link.cached_url + "#" + item.link.anchor}>
                            {item.name}
                        </AnchorLink> :
                        <NavItemLink
                            key={item._uid}
                            className={props.className}
                            activeClassName={"active"}
                            partiallyActive={true}
                            to={"/" + item.link.cached_url}
                        >
                            {item.name}
                        </NavItemLink>
                    )}
                </DropDown>
            }
        </Wrapper>
    )
}

export default NavItem