import React from 'react'

import { StoryblokBase, StoryblokComponentProps } from '../../types';
import SwipeMenu, { MenuItem, SwipeMenuProps } from './SwipeMenu';


interface SwipeMenuReferenceProps extends StoryblokBase {
    swipeMenu: {
        content: {
            menuItems: MenuItem[];
        }
    }
    title: string;
    location: Location
}

interface StoryblokComponentSwipeMenuProps extends StoryblokComponentProps<SwipeMenuReferenceProps> {
    location: Location
}

const SwipeMenuReference = (props: StoryblokComponentSwipeMenuProps) => {
    const { blok } = props;
    const content = blok.swipeMenu.content as SwipeMenuProps;

    return (
        <SwipeMenu blok={content} />
    )
}

export default SwipeMenuReference