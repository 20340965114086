import React from 'react'

import { IconProps } from './index'

export const LocationStar = (props: IconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill={props.color ? props.color : "#000"} className={props.className} height={props.height ? props.height : 30.13} viewBox="0 0 20.42 30.13" width={props.width ? props.width : 20.42} >
            <path d="M10.33,30.13,8.81,29c.09-.13,9.69-12.66,9.69-18.75a8.29,8.29,0,0,0-16.57,0C1.93,15.05,9,25,9.07,25.13L7.5,26.25c-.31-.43-7.5-10.59-7.5-16a10.21,10.21,0,0,1,20.42,0C20.42,17,10.74,29.6,10.33,30.13Z" /><polygon points="15.04 8.57 11.71 8.06 10.21 4.88 8.71 8.06 5.39 8.57 7.8 11.03 7.22 14.53 10.21 12.88 13.2 14.53 12.62 11.03 15.04 8.57" />
        </svg >
    )
}