import * as React from 'react'
import styled from 'styled-components';

const Text = styled.span`
    font-size: 1.5rem;
    font-family: ${({ theme }) => theme.font.headings.family};
    font-weight: ${({ theme }) => theme.font.headings.weight.regular};
    text-transform: uppercase;
    padding: 2px 0;
    border-top: 1px solid ${({ theme }) => theme.colors.fonts.body};
    border-bottom: 1px solid ${({ theme }) => theme.colors.fonts.body}; 
`

const BorderText: React.FC = ({ children }) => {
    return (
        <Text>{children}</Text>
    )
}

export default BorderText;