import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import { StoryblokImage } from '../../types'
import { TransformImage } from '../Helper'
import { CardImage } from '../LocationGrid/LocationCard'


export interface CardProps {
    className?: string,
    link: string,
    title: string,
    subTitle: string,
    type?: string,
    image: StoryblokImage
}

const Wrapper = styled.div`
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    transition: transform 0.3s;
    cursor: pointer;
    &:hover {
        transform: translate(0px, -6px);
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    }

    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
`

const TitleSection = styled.div`
    display: block;
    text-align: center;
    padding: 16px 12px;
    background-color: ${({ theme }) => theme.colors.colors.White};
`
const Title = styled.p`
    color: ${({ theme }) => theme.colors.colors.Black};
    font-family: ${({ theme }) => theme.font.headings.family};
    font-weight: ${({ theme }) => theme.font.headings.weight.medium};
    font-size: 1.15rem;
    line-height: 1rem;
    margin: 0;
    text-transform: uppercase;
`
const SubTitle = styled.p`
    display: block;
    color: ${({ theme }) => theme.colors.colors.Grey3};
    font-family: ${({ theme }) => theme.font.headings.family};
    font-weight: ${({ theme }) => theme.font.headings.weight.regular};
    padding-top: 5px;
    font-size: 0.8rem;
    line-height: 0.8rem;
    text-transform: uppercase;
    margin: 0;
`

const StyledLink = styled(Link)`
    display: block;
    text-decoration:none;
`

const Card = (props: CardProps) => {
    return (
        <Wrapper className={props.className && props.className}>
            <StyledLink to={props.link}>
                <CardImage src={TransformImage(props.image.filename, "300x0", "filters:quality(80)")} alt={props.image.alt} />
                <TitleSection>
                    <Title>{props.title}</Title>
                    <SubTitle>{props.subTitle}</SubTitle>
                </TitleSection>
            </StyledLink>
        </Wrapper>
    )
}

export default Card