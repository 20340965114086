import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import styled from 'styled-components'
import { StoryblokBase } from '../../types'
import Card, { CardProps } from '../Card/Card'
import { JobProps } from '../Job/Job'

import { mediaQueries } from '../Layout/Themes'
import { getStoryblokClient } from '../storyBlokClient'

const Grid = styled.div`
    display: grid;
    grid-gap: 12px;
    margin: 12px;
    margin-bottom: 5rem;

    ${mediaQueries("Mobile")`
        grid-template-columns: repeat(2, 1fr);
    `};

    ${mediaQueries("Tablet")`
        grid-template-columns: repeat(4, 1fr);
    `};
`

const Heading = styled.h2`
    text-align: center;
    margin-bottom: 24px;
`

interface JobGridProps {

}

const JobGrid = (props: JobGridProps) => {
    const [jobs, setJobs] = useState<StoryblokBase[]>([])

    const storiesPath = 'karriere/aktuelle-stellen/'

    useEffect(() => {
        const client = getStoryblokClient()
        client.getAll('cdn/stories', {
            starts_with: storiesPath
        }).then((stories) => {
            setJobs(stories)
        }).catch((error) => {
            console.error(error)
        })
    }, [])

    const onlyJobsList = jobs.filter((job) => job.content.component !== "page");
    const sellerList: CardProps[] = [];
    const administrationList: CardProps[] = [];
    const productionList: CardProps[] = [];

    onlyJobsList.map((element) => {
        let slug = "/karriere/aktuelle-stellen/" + element.slug
        let job = element.content as JobProps;
        let currentJobCounter = job.currentJobs?.length;
        if (job.visible) {
            const newJob: CardProps = {
                image: job.previewImage,
                title: job.title + " (" + currentJobCounter + ")",
                subTitle: job.subTitle,
                link: slug
            }
            switch (job.area) {
                case "Verkauf":
                    sellerList.push(newJob)
                    break;
                case "Verwaltung":
                    administrationList.push(newJob)
                    break;
                case "Produktion":
                    productionList.push(newJob)
                    break;
                default:
                    break;
            }
        }
    })

    return (
        <div>
            {sellerList.length > 0 && <Heading>Arbeiten im Verkauf bei Ziegler</Heading>}
            <Grid>
                {
                    sellerList.map((job, index) =>
                        <Card key={"job_" + index} image={job.image} link={job.link} title={job.title} subTitle={job.subTitle} />
                    )
                }
            </Grid>

            {productionList.length > 0 && <Heading>Arbeiten in der Produktion bei Ziegler</Heading>}
            <Grid>
                {
                    productionList.map((job, index) =>
                        <Card key={"job_" + index} image={job.image} link={job.link} title={job.title} subTitle={job.subTitle} />
                    )
                }
            </Grid>

            {administrationList.length > 0 && <Heading>Arbeiten in der Verwaltung bei Ziegler</Heading>}
            <Grid>
                {
                    administrationList.map((job, index) =>
                        <Card key={"job_" + index} image={job.image} link={job.link} title={job.title} subTitle={job.subTitle} />
                    )
                }
            </Grid>
        </div>
    )
}

export default JobGrid