import React from 'react'
import styled from 'styled-components'

import { mediaQueries } from './Themes'
import Logo from './Logo'
import Copyright from '../Copyright/Copyright'
import { Column2, ContainerBoxed } from './Grid'
import Components from '../Components'

const Wrapper = styled.footer`
    background: ${({ theme }) => theme.colors.backgrounds.footer};
    color: ${({ theme }) => theme.colors.fonts.body};
    padding-top: 120px;
`

const FooterNavWrapper = styled.div`
  display: grid;
  padding: 0 4rem;
  justify-items: center;
  margin-top: 4rem;
  margin-bottom: 3rem;

  ${mediaQueries("Mobile")`
  `};

  ${mediaQueries("Tablet")`
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  `};
`

const Column2Center = styled(Column2)`
  text-align: center;
`

interface FooterProps {
    navItems: any[]
}

const Footer = (props: FooterProps) => {

    return (
        <Wrapper>
            <ContainerBoxed>
                <Column2Center>
                    <Logo type="dark" height="80" />
                    <FooterNavWrapper>
                        {props.navItems.map((blok) => React.createElement(Components(blok.component), { key: blok._uid, blok: blok }))}
                    </FooterNavWrapper>
                    <Copyright text="© Ziegler´s Backstube 2020" />
                </Column2Center>
            </ContainerBoxed>
        </Wrapper>
    )
}

export default Footer