import React from "react";
import { useApplicationCtx } from "./ApplicationCtx";
import { NavButtons } from "./NavButtons";
import { RadioGroup, RadioButton } from "./RadioGroup";
import { data } from "./data";

export const Qualifications = () => {
  const { answers } = useApplicationCtx();

  const position = answers.get("Stelle");
  const qualifications = data.find((x) => x.position === position)
    ?.qualifications;

  return (
    <>
      <h2>Frage 1 von 4</h2>

      <p>
        Welche Qualifikationen bringst du für die ausgeschriebene Stelle mit?
      </p>

      <RadioGroup groupKey="Qualifikation">
        {qualifications?.map((x) => (
          <RadioButton key={x.label} label={x.label} />
        ))}
      </RadioGroup>

      <NavButtons
        previousPage="p4-your-day"
        nextPage={(() => {
          const answer = answers.get("Qualifikation");
          const exit = qualifications?.find((x) => x.label === answer)?.exit;
          return exit ? "nope" : "q2-experience";
        })()}
        nextDisabled={answers.get("Qualifikation") == null}
      />
    </>
  );
};
