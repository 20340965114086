import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import { StoryblokBase, StoryblokComponentProps } from '../../types'
import { FilialeProps } from '../Filiale/Filiale'
import { LocationStar } from '../Icons'
import { getStoryblokClient } from '../storyBlokClient'
import LocationCard from './LocationCard'
import Map from './../Map/Map'
import { Spacing } from '../Layout/Grid'
import { mediaQueries } from '../Layout/Themes'

interface LocationGridProps extends StoryblokBase {

}

const Wrapper = styled.div`
    max-width: 100%;
    overflow-x: hidden;
`

const Grid = styled.div`
    display: grid;
    grid-gap: 12px;
    margin: 12px;

    ${mediaQueries("Mobile")`
        grid-template-columns: repeat(2, 1fr);
    `};

    ${mediaQueries("Tablet")`
        grid-template-columns: repeat(4, 1fr);
    `};
`

const FilialeTyp = {
    Cafe: "cafe",
    Bakery: "baeckerei",
    ExperienceCafe: "erlebnisCafe",
    SuperMarket: "markt"
}

const SectionHeading = styled.h2`
    text-align: center;
    svg {
        padding-left: 12px;
    }
`

const FilterMenuWrapper = styled.div`
    max-width: 100%;
    text-align: center;
    position: relative;
    z-index: 20;
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    ::-webkit-scrollbar {
        display: none;
    }
`

const MenuItemSelectedStyle = css`
    border-top: ${({ theme }) => "1px solid " + theme.colors.swipeMenu.selected};
    border-bottom: ${({ theme }) => "1px solid " + theme.colors.swipeMenu.selected};
        color: ${({ theme }) => theme.colors.swipeMenu.selected};
    &:hover {
            color: ${({ theme }) => theme.colors.swipeMenu.selected};
            cursor: default;
    }
`

const MenuItemStyle = css<{ active: boolean }>`
    font-size: 1.1rem;
    font-weight: ${({ theme }) => theme.font.headings.weight.regular};
    font-family:    ${({ theme }) => theme.font.headings.family};
    text-transform: uppercase;
    display: inline-block;
    vertical-align: middle;
    padding: 0 0.25rem;
    margin: 0 1rem;
    cursor: pointer;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.swipeMenu.color};
    transition: all 0.30s linear;
    border-top: ${({ theme }) => "1px solid transparent"};
    border-bottom: ${({ theme }) => "1px solid transparent"};
    &:hover {
            color: ${({ theme }) => theme.colors.swipeMenu.hover};
    }
    ${({ active }) => active && MenuItemSelectedStyle}
`

const MenuItem = styled.a`
    ${MenuItemStyle}
`;

const HeadingMapper = (heading: string) => {
    switch (heading) {
        case "all":
            return "Alle Standorte"
        case "cafe":
            return "Unsere Bäckerei-Cafés"
        case "baeckerei":
            return "Unsere Bäckerei-Fachgeschäfte"
        case "markt":
            return "Unsere Bäckerei-Fachgeschäfte im Markt"
        default:
            break;
    }
}

const LocationGrid = (props: StoryblokComponentProps<LocationGridProps>) => {
    const [locations, setLocations] = useState<StoryblokBase[]>([])
    const [visibleSection, setVisibleSection] = useState("cafe")

    useEffect(() => {
        const client = getStoryblokClient()
        client.getAll('cdn/stories', {
            starts_with: 'standorte/'
        }).then((stories) => {
            setLocations(stories)
        }).catch((error) => {
            console.error(error)
        })
    }, [])

    // When navigate from a other page with a hash to a section
    useEffect(() => {
        let hash = props.location.hash.replace('#', '');
        if (hash) {
            setVisibleSection(hash);
        }
    })

    const changeFilter = (filter: string) => {
        setVisibleSection(filter);
    }
    // get only filialen
    const locationList = locations.filter((location) => location.content.component === "filiale");
    // get only a specific part of filialen


    let filialList = [];
    // separate Section ErlebnisCafe
    let experienceCafeList = [];

    locationList.forEach(element => {
        if (visibleSection === 'all') {
            filialList = [...locationList]
            return;
        }
        if ((element.content as FilialeProps).typ.toString() === visibleSection) {
            filialList.push(element)
        }
        // ErlebnisCafe kommen in separate liste
        if ((element.content as FilialeProps).typ.toString() === FilialeTyp.ExperienceCafe) {
            experienceCafeList.push(element)
        }
    });
    return (
        <Wrapper>
            <Spacing size={4} />
            <Map locations={locationList} />
            <Spacing size={6} />
            <FilterMenuWrapper>
                <MenuItem active={visibleSection === 'all'} href={"#all"} onClick={() => changeFilter('all')}>Alle Standorte</MenuItem>
                <MenuItem active={visibleSection === (FilialeTyp.Cafe || FilialeTyp.ExperienceCafe)} href={"#" + FilialeTyp.Cafe} onClick={() => changeFilter(FilialeTyp.Cafe)}>Café</MenuItem>
                <MenuItem active={visibleSection === (FilialeTyp.Bakery)} href={"#" + FilialeTyp.Bakery} onClick={() => changeFilter(FilialeTyp.Bakery)}>Bäckereifilialen</MenuItem>
                <MenuItem active={visibleSection === (FilialeTyp.SuperMarket)} href={"#" + FilialeTyp.SuperMarket} onClick={() => changeFilter(FilialeTyp.SuperMarket)}>Marktfilialen</MenuItem>
            </FilterMenuWrapper>

            {
                visibleSection === FilialeTyp.Cafe &&
                <div>
                    <SectionHeading>Unsere Erlebnis-Cafés <LocationStar height={24} /></SectionHeading>
                    <Grid>
                        {
                            experienceCafeList.map((element) =>
                                <LocationCard
                                    key={element.id}
                                    image={(element.content as FilialeProps).previewImage} link={element.slug}
                                    title={(element.content as FilialeProps).title}
                                    showIcon={true} />
                            )
                        }
                    </Grid>
                </div>
            }

            <div>
                <SectionHeading>{HeadingMapper(visibleSection)}</SectionHeading>
                <Grid>
                    {
                        filialList.map((element) =>
                            <LocationCard
                                key={element.id}
                                image={(element.content as FilialeProps).previewImage} link={element.slug}
                                title={(element.content as FilialeProps).title}
                                showIcon={false} />
                        )
                    }
                </Grid>
            </div>
        </Wrapper>
    )
}

export default LocationGrid