import * as React from 'react'
import { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components'

import { StoryblokBase, StoryblokComponentProps } from '../../types';
import { Column2, ContainerBoxedRelative, GridItemOverflowHidden } from '../Layout/Grid';

import SignageLight from './../../images/SignageLight.png'
import SignageDark from './../../images/SignageDark.png'
import { mediaQueries } from '../Layout/Themes';
import Card, { GridCard } from '../Layout/Card';

export interface LandingGridProps extends StoryblokBase {
    cards: GridCard[];
}

const ItemsGrid = styled.div`
    position: relative;
    z-index: 10;
    display: grid;
    grid-gap: 12px;
    margin: 12px;

    ${mediaQueries("Mobile")`
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(6, 1fr);
    `};

    ${mediaQueries("Tablet")`
        grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
        grid-template-rows: repeat(4, minmax(170px, 1fr));
    `};
`

const SignageImage = styled.img`
    position: absolute;
    z-index: 1;
    bottom: -60px;
    right: 60px;
    height: 480px;
    width: auto;
`

const TabletLandingGridWrapper = styled.div`
    ${mediaQueries("Mobile")`
        display: none;
    `};

    ${mediaQueries("Tablet")`
        display: block;
    `};
`

export const LandingGrid = (props: StoryblokComponentProps<LandingGridProps>) => {
    const { blok } = props;
    const themeContext = useContext(ThemeContext);

    return (
        <TabletLandingGridWrapper>
            <ContainerBoxedRelative>
                <Column2>
                    <ItemsGrid>
                        <GridItemOverflowHidden gridColumnStart={1} gridColumnEnd={5} gridRowStart={1} gridRowEnd={2} >
                            <Card card={blok.cards && blok.cards[0]} />
                        </GridItemOverflowHidden>
                        <GridItemOverflowHidden gridColumnStart={1} gridColumnEnd={3} gridRowStart={2} gridRowEnd={3} >
                            <Card card={blok.cards && blok.cards[1]} />
                        </GridItemOverflowHidden>
                        <GridItemOverflowHidden gridColumnStart={3} gridColumnEnd={5} gridRowStart={2} gridRowEnd={3} >
                            <Card card={blok.cards && blok.cards[2]} />
                        </GridItemOverflowHidden>
                        <GridItemOverflowHidden gridColumnStart={1} gridColumnEnd={5} gridRowStart={3} gridRowEnd={4} >
                            <Card card={blok.cards && blok.cards[3]} />
                        </GridItemOverflowHidden>
                        <GridItemOverflowHidden gridColumnStart={1} gridColumnEnd={2} gridRowStart={4} gridRowEnd={5} >
                            <Card card={blok.cards && blok.cards[4]} />
                        </GridItemOverflowHidden>
                        <GridItemOverflowHidden gridColumnStart={2} gridColumnEnd={3} gridRowStart={4} gridRowEnd={5} >
                            <Card card={blok.cards && blok.cards[5]} />
                        </GridItemOverflowHidden>
                        <GridItemOverflowHidden gridColumnStart={3} gridColumnEnd={4} gridRowStart={4} gridRowEnd={5} >
                            <Card card={blok.cards && blok.cards[6]} />
                        </GridItemOverflowHidden>
                        <GridItemOverflowHidden gridColumnStart={4} gridColumnEnd={5} gridRowStart={4} gridRowEnd={5} >
                            <Card card={blok.cards && blok.cards[7]} />
                        </GridItemOverflowHidden>
                    </ItemsGrid>
                </Column2>
                <SignageImage src={themeContext.name === 'dark' ? SignageDark : SignageLight} alt="Ziegler´s Backstube" />
            </ContainerBoxedRelative>
        </TabletLandingGridWrapper>
    )
}

const MobileLandingGridWrapper = styled.div`
    ${mediaQueries("Mobile")`
        display: default;
    `};

    ${mediaQueries("Tablet")`
        display: none;
    `};
`

export const MobileLandingGrid = (props: StoryblokComponentProps<LandingGridProps>) => {
    const { blok } = props;
    const themeContext = useContext(ThemeContext);

    return (
        <MobileLandingGridWrapper>
            <ContainerBoxedRelative>
                <Column2>
                    <ItemsGrid>
                        <GridItemOverflowHidden gridColumnStart={1} gridColumnEnd={3} gridRowStart={1} gridRowEnd={2} >
                            <Card imagePosition="right" card={blok.cards && blok.cards[0]} />
                        </GridItemOverflowHidden>
                        <GridItemOverflowHidden gridColumnStart={1} gridColumnEnd={3} gridRowStart={2} gridRowEnd={3} >
                            <Card card={blok.cards && blok.cards[1]} />
                        </GridItemOverflowHidden>
                        <GridItemOverflowHidden gridColumnStart={1} gridColumnEnd={3} gridRowStart={3} gridRowEnd={4} >
                            <Card card={blok.cards && blok.cards[2]} />
                        </GridItemOverflowHidden>
                        <GridItemOverflowHidden gridColumnStart={1} gridColumnEnd={3} gridRowStart={4} gridRowEnd={5} >
                            <Card imagePosition="left" card={blok.cards && blok.cards[3]} />
                        </GridItemOverflowHidden>
                        <GridItemOverflowHidden>
                            <Card card={blok.cards && blok.cards[4]} />
                        </GridItemOverflowHidden>
                        <GridItemOverflowHidden>
                            <Card card={blok.cards && blok.cards[5]} />
                        </GridItemOverflowHidden>
                        <GridItemOverflowHidden>
                            <Card card={blok.cards && blok.cards[6]} />
                        </GridItemOverflowHidden>
                        <GridItemOverflowHidden>
                            <Card card={blok.cards && blok.cards[7]} />
                        </GridItemOverflowHidden>
                    </ItemsGrid>
                </Column2>
                <SignageImage src={themeContext.name === 'dark' ? SignageDark : SignageLight} />
            </ContainerBoxedRelative>
        </MobileLandingGridWrapper>
    )
}