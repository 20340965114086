import React, { useState } from 'react'
import styled from 'styled-components'
import GoogleMapReact from 'google-map-react'

import config from '../../../gatsby-config'
import Marker, { MarkerProps } from './Marker'
import MapStyle from './mapsTheme.json'
import { FilialeProps } from '../Filiale/Filiale'
import { mediaQueries } from '../Layout/Themes'

const Wrapper = styled.div`
    ${mediaQueries("Mobile")`
        display: none;
    `};

    ${mediaQueries("Tablet")`
        display: block;
        height: 600px;
        width: 100%;
    `};
`

interface MapSettings {
    center: {
        lat: number,
        lng: number
    },
    zoom: number
}

interface MapProps {
    locations: any[]
}

const getApiKeyFromConfig = () => {
    return config.siteMetadata.mapsApiKey
}

const Map = (props: MapProps) => {
    // Schopfloch as center of the world
    const defaultProps: MapSettings = {
        center: { lat: 48.45, lng: 8.53 }, zoom: 10
    }
    const [mapProps, setMapProps] = useState<MapSettings>(defaultProps)

    const locationMarkers: MarkerProps[] = []

    props.locations.map((element) => {
        let newMarker: MarkerProps = {
            slug: element.slug,
            filiale: element.content as FilialeProps,
            uuid: element.uuid
        }
        locationMarkers.push(newMarker)
    })

    return (
        <Wrapper>
            <GoogleMapReact
                bootstrapURLKeys={{ key: getApiKeyFromConfig() }}
                defaultCenter={mapProps.center}
                defaultZoom={mapProps.zoom}
                options={{ styles: MapStyle }}
                hoverDistance={20}
            >
                {
                    locationMarkers.map((element) =>
                        <Marker
                            key={element.uuid}
                            filiale={element.filiale}
                            slug={element.slug}
                            lat={element.filiale.lat}
                            lng={element.filiale.lng}
                        />
                    )
                }

            </GoogleMapReact>
        </Wrapper>
    )
}

export default Map