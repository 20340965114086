import * as React from 'react'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image, DotGroup } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import styled, { css } from 'styled-components'

import { StoryblokBase, StoryblokComponentProps, StoryblokImage } from '../../types'
import { TransformImage } from '../Helper'
import ArrowGold from './arrow_gold.svg'
import ArrowBlack from './arrow_black.svg'
import { mediaQueries } from '../Layout/Themes'

export interface SliderProps extends StoryblokBase {
    color: string,
    aspectRatio: string,
    slides: SliderItem[]
}

interface FeatureText {
    subtext: string,
    text: string
    subHeading: string;
    subHeadingGold: string;
    align?: string;
}

interface SliderItem {
    featureText: FeatureText[];
    image: StoryblokImage;
    _uid: string;
}

const AspectRatioContainer = styled.div<{ aspectRatio: string[] }>`
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 100%;
    padding-top: ${({ aspectRatio }) => 'min(calc(' + aspectRatio[1] + '/' + aspectRatio[0] + '* 100%), calc(100vh - 84px))'};
`

const AspectChild = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
`

const BaseSliderButtonStyle = css`
    position: absolute;
    top: calc(50% - 24px);
    height: 48px;
    width: 48px;
    padding: 0;
    background: unset;
    border: unset;
    opacity: 0.7;
    transition: all 0.30s linear;
    &:hover:enabled {
        opacity: 1;
    }
    &:disabled {
        opacity: 0;
        cursor: default;
    }
`

const SliderBackButton = styled(ButtonBack)`
    ${BaseSliderButtonStyle}
    transform: rotate(180deg);

    ${mediaQueries("Mobile")`
        height: 24px;
        width: auto;
        img {
            height: 24px;
        }
    `};

    ${mediaQueries("Tablet")`
        height: 48px;
        width: auto;
        img {
            height: 48px;
        }
    `};
`

const SliderNextButton = styled(ButtonNext)`
    ${BaseSliderButtonStyle}
    right: 0;

    ${mediaQueries("Mobile")`
        height: 24px;
        width: auto;
        img {
            height: 24px;
        }
        right: 0;
    `};

    ${mediaQueries("Tablet")`
        height: 48px;
        width: auto;
        img {
            height: 48px;
        }
    `};
`

const SliderCounter = styled(DotGroup)`
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;

    ${mediaQueries("Mobile")`
        margin-bottom: 8px;
    `};

    ${mediaQueries("Tablet")`
        margin-bottom: 36px;
    `};


    button {
        display: block;
        padding: 0;
        width: 4em;
        height: 2px;
        border: 0;
        margin: 10px;
        outline: 0;
        background: ${({ theme }) => theme.colors.slider.sliderDots};
        opacity: 0.5;
        transition: all 0.30s linear;
        &:after {
            content: "";
            display: block;
            height: 20px;
            position: relative;
            top: -10px;
        }
        &.carousel__dot--selected, :hover {
            background: ${({ theme }) => theme.colors.slider.sliderDotsActive};
            opacity: 1;
        }
    }
`

const InnerSlide = styled.div`
    position: relative;
    display: flex;
`

const FeatureTextStyle = styled.h1<{ size: number }>`
    z-index: 100;
    font-family: ${({ theme }) => theme.font.headings.family};
    font-weight: ${({ theme }) => theme.font.headings.weight.medium};
    color: ${({ theme }) => theme.colors.slider.heading};
    font-size: ${({ size }) => size + "rem"};
    line-height: ${({ size }) => size + "rem"};
    transform: skew(-3deg) rotate(-3deg);
    text-transform: uppercase;
    text-align: center;
    white-space: pre-line;

    ${mediaQueries("Mobile")`
        font-size: 1rem;
        line-height: 1rem;
    `}

    ${mediaQueries("Tablet")`
        font-size: 3rem;
        line-height: 3rem;
    `};

    ${mediaQueries("Desktop")`
        font-size: 5rem;
        line-height: 5rem;
    `};
`

const SliderFeatureTextContainer = styled.div<{ align: string }>`
    position: absolute;
    max-width: 1000px;
    top: 20%;
    ${({ align }) => {
        switch (align) {
            case "left":
                return "left: 15%";
            case "center":
                return "left: 30%";
            case "right":
                return "left: 40%";
            default:
                return "left: 20%";
        }
    }
    }
`

export const SliderGoldTextStyle = styled.span`
    font-family: ${({ theme }) => theme.font.headings.family};
    font-weight: ${({ theme }) => theme.font.headings.weight.medium};
    color: ${({ theme }) => theme.colors.fonts.navLinkHover};
    transform: skew(-3deg) rotate(-3deg);
    border-top: 2px solid ${({ theme }) => theme.colors.fonts.navLinkHover};
    border-bottom: 2px solid ${({ theme }) => theme.colors.fonts.navLinkHover};
    text-transform: uppercase;


    margin-bottom: 0;

    ${mediaQueries("Mobile")`
        font-size: 0.4rem;
        line-height: 0.4rem;
        border-width: 1px;
        margin-right: 4px;
        padding: 0 2px;
        position: relative;
        top: -3px;
    `}

    ${mediaQueries("Tablet")`
        font-size: 1.3rem;
        line-height: 1.3rem;
        position: relative;
        top: -10px;
        border-width: 2px;
        margin-right: 16px;
        padding: 0 8px;
    `};

    ${mediaQueries("Desktop")`
        font-size: 2rem;
        line-height: 2rem;
        position: relative;
        top: -15px;
        border-width: 2px;
        margin-right: 16px;
        padding: 0 8px;
    `};
`

const CarouselProviderStyle = styled(CarouselProvider)`
    position: relative;
`

const SubHeading = styled.h3`
    color: ${({ theme }) => theme.colors.colors.White};
    text-align: right;

    ${mediaQueries("Mobile")`
        font-size: 0.6rem;
        margin-top: 0;
    `}

    ${mediaQueries("Tablet")`
        font-size: 1.75rem;
        margin-top: 3rem;
    `};
`
const SubHeadingGold = styled.span`
    color: ${({ theme }) => theme.colors.colors.Beige};
`

const MainSlider = (props: StoryblokComponentProps<SliderProps>) => {
    const { blok } = props;
    const aspect = blok.aspectRatio?.split('/');

    return (
        blok?.slides ?
            <AspectRatioContainer aspectRatio={aspect ? aspect : ["16", "9"]} >
                <AspectChild>
                    <CarouselProviderStyle
                        naturalSlideWidth={100}
                        naturalSlideHeight={125}
                        totalSlides={blok.slides.length}
                        isIntrinsicHeight={true}
                        dragEnabled={blok.slides.length > 1}
                        interval={6000}
                        isPlaying={true}
                    >
                        <Slider style={{ maxHeight: 'calc(100vh - 100px)' }} aria-label="slider" >
                            {blok.slides.map((slide, index) =>
                                <Slide key={slide._uid} index={index}>
                                    <InnerSlide>
                                        <Image hasMasterSpinner={true} src={TransformImage(slide.image.filename, "", "filters:quality(80)")} alt={slide.image.alt} />
                                        {slide.featureText?.length > 0 &&
                                            <SliderFeatureTextContainer align={slide.featureText[0].align}>
                                                <FeatureTextStyle size={5}>
                                                    {slide.featureText[0].subtext != "" && <SliderGoldTextStyle>{slide.featureText[0].subtext}</SliderGoldTextStyle>}
                                                    {slide.featureText[0].text}
                                                </FeatureTextStyle>
                                                {slide.featureText[0]?.subHeading && <SubHeading><SubHeadingGold>{slide.featureText[0]?.subHeadingGold}</SubHeadingGold> {slide.featureText[0]?.subHeading}</SubHeading>}
                                            </SliderFeatureTextContainer>
                                        }
                                    </InnerSlide>
                                </Slide>
                            )}
                        </Slider>
                        <SliderBackButton><img src={blok.color && blok.color[0] === "black" ? ArrowBlack : ArrowGold} alt="Zurück" /></SliderBackButton>
                        <SliderNextButton><img src={blok.color && blok.color[0] === "black" ? ArrowBlack : ArrowGold} alt="Vor" /></SliderNextButton>
                        {blok.slides.length > 1 ? <SliderCounter /> : <React.Fragment />}
                    </CarouselProviderStyle>
                </AspectChild>
            </AspectRatioContainer > : <p> Please add Slides </p>
    )
}

export default MainSlider;