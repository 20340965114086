import React, { useState } from 'react'
import styled from 'styled-components'
import { mediaQueries } from './Themes'

import Logo from './Logo'
import { StoryblokBase } from '../../types'
import NavItem, { NavItemProps } from './NavItem'
import Social from '../Social/Social'
import { MittagsTischLink } from './Nav'
import Hamburger from './Hamburger'

const Wrapper = styled.div`
    ${mediaQueries("Mobile")`
        display: block;
    `};

    ${mediaQueries("Tablet")`
        display: none;
    `};
    overflow: hidden;
`

const Content = styled.div<{ isOpen: boolean }>`
    visibility: ${props => props.isOpen ? "visible" : "hidden"};
    position: fixed;
    z-index: 1000;
    top: 0px;
    left: 0px;
    right: 0px;
    height: ${props => props.isOpen ? "100%" : "0"};
    transition: 0.3s;
    transition-delay: ${props => props.isOpen ? "0" : "0.2s"};
    background: ${({ theme }) => theme.colors.backgrounds.body};
    a {
        transition-delay: ${props => props.isOpen ? "0.1s" : "0s"};
    }

`
const Grid = styled.div<{ isOpen: boolean }>`
    height: 100%;
    display: grid;
    grid-template-rows: 120px repeat(auto-fill, minmax(50px,60px));
    justify-items: center;
    align-items: center;
    visibility:  ${props => props.isOpen ? "visible" : "hidden"};
`

const Toggler = styled.div`
    position: fixed;
    top: 12px;
    right: 12px;
    cursor: pointer;
    padding: 12px;
    z-index: 10001;
`

const LogoContainer = styled.div`
    position: fixed;
    z-index: 1001;
    top: 12px;
    left: 12px;
`

const Header = styled.div`
    position: fixed;
    z-index: 1001;
    background: ${({ theme }) => theme.colors.backgrounds.body};
    height: 84px;
    width: 100%;
`

interface MobileNavProps {
    navItems: StoryblokBase[],
    location: Location,
    children: any
}

const MobileNav = (props: MobileNavProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <Wrapper>
            <Header>
                <LogoContainer>
                    <Logo height="50" onClick={() => setIsOpen(false)} />
                </LogoContainer>
                <Toggler onClick={toggle}>
                    <Hamburger isOpen={isOpen} />
                </Toggler>
            </Header>
            <Content isOpen={isOpen}>
                <Grid isOpen={isOpen}>
                    <div />
                    {
                        props.navItems && props.navItems.map((blok) =>
                            <NavItem
                                onClick={() => setIsOpen(false)}
                                key={blok._uid}
                                blok={blok as NavItemProps}
                                location={props.location} />
                        )
                    }
                    <div />
                    <MittagsTischLink onClick={() => setIsOpen(false)} />
                    <div />
                    <div>
                        <Social />
                    </div>
                    {props.children}
                </Grid>
            </Content>
        </Wrapper>
    )
}

export default MobileNav