import * as React from 'react'
import styled, { withTheme } from 'styled-components'

import { StoryblokBase, StoryblokComponentProps } from '../../types';

import LogoDark from './../../images/BigLogoBlack.svg'
import LogoLight from './../../images/BigLogoWhite.svg'
import { mediaQueries, ThemeType } from './Themes';


interface BigLogoProps extends StoryblokBase {
    type?: string;
    height?: string;

}

interface BigLogoCompoentProps extends StoryblokComponentProps<BigLogoProps> {
    theme: ThemeType;
}

const CenterImage = styled.img`
    ${mediaQueries("Mobile")`
        display: none;
    `};

    ${mediaQueries("Tablet")`
        display: block;
        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    `};
`

const BigLogo: React.FC<BigLogoCompoentProps> = (props) => {
    const { blok } = props;

    let logoType = ""
    if (blok.type) {
        logoType = blok.type
    } else {
        logoType = props.theme.name;
    }

    return (
        <CenterImage style={{ textAlign: "center" }} src={logoType === "light" ? LogoDark : LogoLight} height={blok.height} alt="Ziegler Logo" />
    )
}

export default withTheme(BigLogo);