import React from "react";
import { useApplicationCtx } from "./ApplicationCtx";
import { RadioButton, RadioGroup } from "./RadioGroup";
import { data } from "./data";

export const Entry = () => {
  const { goTo } = useApplicationCtx();

  const positions = data.map((x) => x.position);

  return (
    <>
      <h2>Aktuelle Stellen</h2>

      <p>Für welche Stelle interessierst du dich?</p>

      <RadioGroup groupKey="Stelle">
        {positions.map((position) => (
          <RadioButton
            key={position}
            label={position}
            onClick={() => goTo("p2-benefits")}
          />
        ))}
      </RadioGroup>
    </>
  );
};
