import * as React from 'react'
import styled from 'styled-components'

import { StoryblokBase, StoryblokComponentProps, StoryblokImage } from '../../types'
import { ContainerBoxedRelative, Spacing } from '../Layout/Grid'
import { mediaQueries } from '../Layout/Themes'
import SignageImage from '../SignageImage/SignageImage'
import { getStoryblokClient } from '../storyBlokClient'

interface AlternateColumnGridProps extends StoryblokBase {
    rows: AlternateColumnGridRow[];
}

interface AlternateColumnGridRow {
    image: StoryblokImage,
    maxWidthImage?: number,
    children: StoryblokBase
}

interface StoryblokComponentAlternateColumnGridProps extends StoryblokComponentProps<AlternateColumnGridProps> {
    location: Location
}

const ItemsGrid = styled.div`
    grid-column: 2;
    position: relative;
    z-index: 10;
    display: grid;

    grid-column-gap: 5rem;
    justify-items: stretch;
    align-items: center;
    width: 100%;
    ${mediaQueries("Mobile")`
        grid-template-columns: 1fr;
        grid-row-gap: 2rem;
    `};

    ${mediaQueries("Tablet")`
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 10rem;
        grid-auto-flow: row dense;
        & > :nth-child(4n+1) {
            grid-column-start: 2;
        }
        & > :first-child{
            grid-column-start: 2;
        }
    `};
`

const Image = styled.img<{ maxWidth: number }>`
    height: auto;
    width: ${({ maxWidth }) => maxWidth ? maxWidth + "px" : "calc(100% - 1rem)"};
    object-fit: cover;
    ${mediaQueries("Mobile")`
        margin: 0 0.5rem;
    `};
`
const GridItem = styled.div`
    width: 100%;
    height: auto;
`

const RichTextContainer = styled.div`
    ${mediaQueries("Mobile")`
        text-align: center;
        margin: 0 0.5rem;
    `};

    ${mediaQueries("Tablet")`
        text-align: left;
    `};

    a {
        color: ${({ theme }) => theme.colors.fonts.body};
        font-weight: 600;
        transition: 0.3s all;
        &:hover {
            opacity: 0.7;
        }
    }
`

const ImageText = styled.p`
    font-size: 0.8rem;
    text-align: center;
`

const AlternateColumnGrid = (props: StoryblokComponentAlternateColumnGridProps) => {
    const { blok } = props;
    const storyBlok = getStoryblokClient();

    storyBlok.setComponentResolver((component, blok) => {
        switch (component) {
            case 'underOverlineText':
                return `<h3 class="under-overline-text">${blok.text}</h3>`
                break;
        }
    })

    return (
        <div>
            <Spacing size={4} />
            <ContainerBoxedRelative>
                <ItemsGrid>
                    {
                        blok.rows.map((row, index) =>
                            <React.Fragment key={"row-" + index}>
                                <GridItem>
                                    <RichTextContainer dangerouslySetInnerHTML={{ __html: storyBlok.richTextResolver.render(row.children) }} />
                                </GridItem>
                                <GridItem>
                                    <Image maxWidth={row.maxWidthImage} src={row.image.filename} alt={row.image.alt} />
                                </GridItem>
                            </React.Fragment>
                        )
                    }
                </ItemsGrid>
                <SignageImage />
            </ContainerBoxedRelative>
            <Spacing size={10} />
        </div>
    )
}

export default AlternateColumnGrid;