import React from "react";
import { useApplicationCtx } from "./ApplicationCtx";
import { Button } from "./Button";
import { Img } from "./Img";
import { data } from "./data";

export const Benefits = () => {
  const { goTo, answers } = useApplicationCtx();

  const position = answers.get("Stelle");
  const benefits = data.find((x) => x.position === position)?.benefits;
  const img = data.find((x) => x.position === position)?.image1;

  return (
    <>
      <h2>
        <span>Deine Vorteile bei uns</span>
        <br />
        <span>Wir bieten dir mehr als nur einen Job</span>
      </h2>

      <Img src={img} />

      <ul>
        {benefits?.map((x) => (
          <li key={x}>{x}</li>
        ))}
      </ul>

      <Button onClick={() => goTo("p3-what-else")}>
        <span>Das war schon alles?</span>
        <br />
        <span>Was bietet ihr mir finanziell?</span>
      </Button>
    </>
  );
};
