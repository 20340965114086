import React, { FormEventHandler, useState } from "react";
import styled from "styled-components";
import { mediaQueries } from "../Layout/Themes";
import { useApplicationCtx } from "./ApplicationCtx";
import { Img } from "./Img";
import Image from "./assets/Bild - Wir würden dich gerne kennenlernen.jpg";

const Label = styled.label`
  text-align: left;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Input = styled.input`
  display: block;
  border: 0;
  background: ${({ theme }) => theme.colors.backgrounds.body};
  color: ${({ theme }) => theme.colors.fonts.body};
  font-family: ${({ theme }) => theme.font.body.family};
  font-weight: ${({ theme }) => theme.font.body.weight.regular};
  border-bottom: 1px solid ${({ theme }) => theme.colors.fonts.body};
  line-height: 2rem;
  font-size: 1rem;
  width: 100%;
`;

const SubmitButton = styled.button`
  border-radius: 4px;
  font-size: 1rem;
  padding: 4px 36px;
  text-transform: uppercase;
  transition: all 0.3s;
  font-family: ${({ theme }) => theme.font.body.family};
  font-weight: ${({ theme }) => theme.font.body.weight.semiBold};
  cursor: pointer;
  margin-top: 4px;
  &:hover {
    opacity: 0.8;
  }
  background: ${({ theme }) => (theme.name === "light" ? "#000" : "#fff")};
  color: ${({ theme }) => (theme.name === "light" ? "#fff" : "#000")};
  border: 1px solid ${({ theme }) => (theme.name === "light" ? "#000" : "#fff")};
  margin-top: 24px;
  &[disabled] {
    opacity: 0.2;
    cursor: unset;
  }
  float: right;

  ${mediaQueries("Mobile")`
    float: unset;
  `};
`;

const Overlay = styled.div`
  display: grid;
  align-items: center;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f7f4ec;
  border-radius: 4px;
  opacity: 0.96;
  transition: 0.3s all;
  text-align: center;
`;

const IsSendingText = styled.h3`
  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }

  span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }

  span:nth-child(2) {
    animation-delay: 0.2s;
  }

  span:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

export const Yope = () => {
  const { answers } = useApplicationCtx();
  const [isSending, setIsSending] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);

    answers.forEach((val, key) => formData.append(key, val));
    console.log(formData);

    try {
      setIsSending(true);
      await postData(
        "https://getform.io/f/63018905-fc2a-4ee6-8ad8-a50c2d78f6f3",
        formData
      );
      setIsSuccess(true);
      form.reset();
    } catch (error) {
      console.error(error);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <>
      {(isSending || isSuccess) && (
        <Overlay>
          {isSending && (
            <IsSendingText>
              Bewerbung wird abgeschickt <span>.</span>
              <span>.</span>
              <span>.</span>
            </IsSendingText>
          )}

          {isSuccess && (
            <div>
              <h2>Vielen Dank für deine Bewerbung</h2>
              <p>Wir melden uns in Kürze bei dir.</p>
            </div>
          )}
        </Overlay>
      )}

      <h2>
        <span>Laut deinen Angaben passt du perfekt in unser Team</span>
        <br />
        <span>Wir würden dich gerne kennenlernen!</span>
      </h2>

      <Img src={Image} />

      <p>Wohin dürfen wir dein Job-Angebot schicken?</p>

      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <FormGroup>
          <Label>
            <span>👨‍💻 Name</span>
            <span aria-hidden>*</span>
            <Input type="text" required name="Name" />
          </Label>

          <Label>
            <span>📧 E-Mail</span>
            <span aria-hidden>*</span>
            <Input type="email" pattern="^(.+)@(.+)$" required name="email" />
          </Label>

          <Label>
            <span>📞 Telefonnummer / WhatsApp</span>
            <span aria-hidden>*</span>
            <Input type="tel" required name="Telefonnummer" />
          </Label>

          <Label>
            <span>🏠 Wohnort</span>
            <span aria-hidden>*</span>
            <Input type="text" required name="Wohnort" />
          </Label>

          <Label>
            <span>⏳ Wann und worüber bist du am besten zu erreichen?</span>
            <Input type="text" name="Erreichbarkeit" />
            <span style={{ fontSize: "0.8rem" }}>
              Bsp.: Nachmittags ab 16 Uhr über Whatsapp
            </span>
          </Label>
        </FormGroup>

        <p style={{ fontSize: "0.8rem" }}>
          Mit Absenden des Formulars stimmst Du zu, dass Deine Angaben aus dem
          Formular zur Beantwortung Deiner Anfrage erhoben und verarbeitet
          werden. Die Daten werden nach abgeschlossener Bearbeitung Deiner
          Anfrage gelöscht. Hinweis: Du kannst Deine Einwilligung jederzeit für
          die Zukunft per E-Mail an <strong>info@zieglers-backstube.de</strong>{" "}
          widerrufen. Detaillierte Informationen zum Umgang mit Nutzerdaten
          findest Du in unserer <a href="/datenschutz">Datenschutzerklärung</a>.
        </p>

        <SubmitButton type="submit">Abschicken</SubmitButton>
      </form>
    </>
  );
};

async function postData(url: string, data: FormData) {
  const response = await fetch(url, {
    method: "POST",
    body: data,
  });
  return response;
}
