import React from 'react'
import styled from 'styled-components'

import { StoryblokBase, StoryblokComponentProps } from '../../types'
import Components from '../Components'
import { RichTextProps } from '../RichText/RichText'

interface Row {
    left: RichTextProps[],
    right: RichTextProps[],
    borderTop: boolean,
}
interface TwoColumnTableProps extends StoryblokBase {
    heading: string,
    subHeading: string,
    isNutritionValues: boolean,
    rows: Row[]
}

const SubHeading = styled.span`
    font-weight: ${({ theme }) => theme.font.headings.weight.regular};
    font-size: 1rem;
    display: block;
    line-height: 1rem;
`

const WeightIndicator = styled.span`
    float: right;
    font-size: 0.75rem;
`

const TableRow = styled.tr<{ isBorderTop: boolean }>`
    border-top: ${({ isBorderTop, theme }) => isBorderTop ? "1px solid " + theme.colors.fonts.body : "none"};
`

const Wrapper = styled.div`
    table {
        width: 100%;
        border-collapse: collapse;

        td {
            vertical-align: top;
        }

        p {
            margin: 0.5rem 0;
        }
    }

    h3 {
        padding: 0 .5rem;
    }
`

const TwoColumnTable = (props: StoryblokComponentProps<TwoColumnTableProps>) => {
    const { blok } = props;

    return (
        <Wrapper>
            <h3>{blok.heading} {blok.isNutritionValues && <WeightIndicator>pro 100g</WeightIndicator>} <br />
                <SubHeading>{blok.subHeading && blok.subHeading}</SubHeading>
            </h3>

            <table>
                <tbody>
                    {blok.rows.map((element, index) =>
                        <TableRow isBorderTop={element.borderTop} key={blok._uid + "_" + index}>
                            <td style={{ fontWeight: "bold" }}>
                                {element.left && element.left.map((blok) =>
                                    React.createElement(Components(blok.component), { key: blok._uid, blok: blok, location: props.location }))
                                }
                            </td>
                            <td>
                                {element.right && element.right.map((blok) => React.createElement(Components(blok.component), { key: blok._uid, blok: blok, location: props.location }))}
                            </td>
                        </TableRow>
                    )}
                </tbody>
            </table>
        </Wrapper>
    )
}

export default TwoColumnTable