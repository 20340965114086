import React from "react";
import { useApplicationCtx } from "./ApplicationCtx";
import { NavButtons } from "./NavButtons";
import { RadioButton, RadioGroup } from "./RadioGroup";

export const Locality = () => {
  const { answers } = useApplicationCtx();

  return (
    <>
      <h2>Frage 3 von 4</h2>

      <p>
        Kommst du aus Schopfloch (bei Freudenstadt, BW) oder Umgebung (0-50 km
        entfernt)
      </p>

      <RadioGroup groupKey="Aus der Umgebung?">
        <RadioButton label="🏠 Ja" />
        <RadioButton label="Nein" />
        <RadioButton label="Nein, ich bin aber offen für einen Umzug in die Region" />
      </RadioGroup>

      <NavButtons
        previousPage="q2-experience"
        nextPage={
          answers.get("Aus der Umgebung?") === "Nein" ? "nope" : "q4-start"
        }
        nextDisabled={answers.get("Aus der Umgebung?") == null}
      />
    </>
  );
};
