import { createGlobalStyle, withTheme } from 'styled-components'

import { ThemeType } from './Themes'
import './../../static/fonts.css'

const GlobalStyles = createGlobalStyle<{ theme: ThemeType }>`
    html {
        overflow-x: hidden;
    }

    body {
        margin: 0;
        font-family: ${(theme) => theme.theme.font.body.family};
        font-weight: ${(theme) => theme.theme.font.body.weight.medium};
        font-size: 1rem;
        line-height: 1.6;
        color: ${(theme) => theme.theme.colors.fonts.body};
    }

    h1,h2,h3,h4,h5,h6 {
        font-family: ${(theme) => theme.theme.font.headings.family};
        font-weight: ${(theme) => theme.theme.font.headings.weight.medium};
        color: ${(theme) => theme.theme.colors.fonts.heading};
        text-transform: uppercase;
    }

    h1 {
        font-size: 3rem;
        line-height: 3rem;
    }

    h2 {
        font-size: 2rem;
        line-height: 2rem;
        margin-top: 3rem;
        margin-bottom: 0rem;
    }

    h3 {
        font-size: 1.75rem;
        line-height: 1.75rem;
        margin-top: 3rem;
        margin-bottom: 0rem;
    }

    h4 {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }

    h5 {
        font-size: 1.25rem;
        line-height: 1.25rem;
    }

    h6 {
        font-size: 1rem;
        line-height: 1rem;
    }

    .under-overline-text {
        display: inline-block;
        font-family: ${({ theme }) => theme.font.headings.family};
        font-weight: ${({ theme }) => theme.font.headings.weight.regular};
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        padding-top: 4px;
        padding-bottom: 2px;
        padding-left: 6px;
        padding-right: 6px;
        text-transform: uppercase;
        margin-bottom: 0;
    }
`

export default withTheme(GlobalStyles)