import React from 'react'
import styled from 'styled-components'

import { StoryblokBase, StoryblokComponentProps, StoryblokImage, StoryblokMetaFields } from "../../types"
import Components from '../Components';
import { Spacing } from '../Layout/Grid';
import { RichTextProps } from '../RichText/RichText';
import { SliderProps } from "../Slider/MainSlider"
import { mediaQueries } from '../Layout/Themes';
import BackButton from '../Layout/BackButton';
import { Helmet } from 'react-helmet';

export interface ProductProps extends StoryblokBase {
    title: string,
    subTitle: string,
    previewImage: StoryblokImage,
    slider: SliderProps,
    richText: RichTextProps,
    ingridients: StoryblokBase,
    nutritionValues: StoryblokBase,
    seo?: StoryblokMetaFields,
}

const Wrapper = styled.div`
    ${mediaQueries("Mobile")`
        padding: 0 0.5rem;
    `}

    ${mediaQueries("Tablet")`
        margin: 0 6rem;
    `}

    ${mediaQueries("Desktop")`
        margin: 0 10rem;
    `}
`

const Grid = styled.div`
    display: grid;
    grid-gap: 5rem;
    padding: 0 8px;

    ${mediaQueries("Mobile")`
        grid-template-columns: 1fr;
    `}

    ${mediaQueries("Tablet")`
        grid-template-columns: 1fr 1px 1fr;
    `}

`

const Divider = styled.div`
    width: 100%;
    border-top: ${({ theme }) => "1px solid " + theme.colors.fonts.body};
    margin: 8rem 0;
`

const VerticalDivider = styled.div`
    height: 100%;
    background-color: ${({ theme }) => theme.colors.fonts.body};

    ${mediaQueries("Mobile")`
       display: none;
    `}

    ${mediaQueries("Tablet")`
        display: block;
    `}
`

const Product = (props: StoryblokComponentProps<ProductProps>) => {
    const { blok } = props;
    const { seo } = blok;
    const slider = blok.slider && blok.slider[0];
    const richText = blok.richText && blok.richText[0];
    const ingridients = blok.ingridients && blok.ingridients[0];
    const nutritionValues = blok.nutritionValues && blok.nutritionValues[0];

    return (
        <React.Fragment>
            <Helmet>
                {seo?.title && <title>{seo.title}</title>}
                {seo?.description && <meta name="description" content={seo.description} />}
            </Helmet>
            <BackButton />
            <Wrapper>
                <div>
                    {slider && React.createElement(Components(slider.component), { key: slider._uid, blok: slider, location: props.location })}
                </div>
                <Spacing size={8} />
                <div>
                    {richText && React.createElement(Components(richText.component), { key: richText._uid, blok: richText, location: props.location })}
                </div>
            </Wrapper>
            {
                (ingridients || nutritionValues) && <Divider />
            }
            <Grid>
                {ingridients && React.createElement(Components(ingridients.component), { key: ingridients._uid, blok: ingridients, location: props.location })}
                <VerticalDivider />
                {nutritionValues && React.createElement(Components(nutritionValues.component), { key: nutritionValues._uid, blok: nutritionValues, location: props.location })}
            </Grid>
            <Spacing size={20} />
        </React.Fragment>
    )
}

export default Product