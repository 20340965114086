import React from 'react'

import { StoryblokBase, StoryblokComponentProps } from '../../types';
import Components from '../Components';
import { Content } from '../ContainerBoxed/ContainerBoxed';
import { ContainerBoxedRelative } from '../Layout/Grid';
import { ProductProps } from '../Product/Product';
import SignageImage from '../SignageImage/SignageImage';

interface ProductPageProps extends StoryblokBase {
    type?: string[],
    product: ProductProps[]
}

const ProductPage = (props: StoryblokComponentProps<ProductPageProps>) => {
    return (
        props.blok.product ?
            <ContainerBoxedRelative>
                <Content>
                    {props.blok.product.map((blok) => React.createElement(Components(blok.component), { key: blok._uid, blok: blok, location: props.location }))}
                </Content>
                <SignageImage />
            </ContainerBoxedRelative> :
            <React.Fragment />
    )
}

export default ProductPage