import React from 'react'
import styled from 'styled-components'

interface Icon {
    children: any,
    text: string,
    visibility: boolean
}

const Wrapper = styled.div<{ visibilty: boolean }>`
    display: grid;
    grid-template-rows: 1fr 1fr;
    opacity: ${({ visibilty }) => visibilty ? 1 : 0.2};
    justify-items: center;
    svg {
        width: 32px;
        height: 32px;
        fill: ${({ theme }) => theme.colors.fonts.body};
    }
`

const Text = styled.p`
    font-family: ${({ theme }) => theme.font.headings.family};
    font-size: .75rem;
    margin: 0.4rem;
`

const Icon = (props: Icon) => {
    return (
        <Wrapper visibilty={props.visibility}>
            {props.children}
            <Text>{props.text}</Text>
        </Wrapper>
    )
}

export default Icon