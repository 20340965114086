import React from 'react'

import { IconProps } from './index'

export const Chevron = (props: IconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={props.className} height={props.height ? props.height : 24} viewBox="0 0 24 24" width={props.width ? props.width : 24}>
            <path d="M0 0h24v24H0z" fill="none" /><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
        </svg>
    )
}