import React from "react";
import { useApplicationCtx } from "./ApplicationCtx";
import { NavButtons } from "./NavButtons";
import { RadioButton, RadioGroup } from "./RadioGroup";

export const Start = () => {
  const { answers } = useApplicationCtx();

  return (
    <>
      <h2>Frage 4 von 4</h2>

      <p>Wann könntest du unser Team frühstens unterstützen?</p>

      <RadioGroup groupKey="Verfügbar ab">
        <RadioButton label="Ab sofort 🕐" />
        <RadioButton label="In 4-5 Wochen 📆" />
        <RadioButton label="In 2-3 Monaten 📅" />
        <RadioButton label="Noch unklar, da im Arbeitsverhältnis" />
      </RadioGroup>

      <NavButtons
        nextPage="yope"
        previousPage="q3-locality"
        nextDisabled={answers.get("Verfügbar ab") == null}
      />
    </>
  );
};
