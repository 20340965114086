import * as React from 'react'
import styled from 'styled-components'

import { StoryblokBase, StoryblokComponentProps, } from '../../types'
import Components from '../Components'
import { ContainerBoxedRelative } from '../Layout/Grid'
import SignageImage from '../SignageImage/SignageImage'

interface ContainerBoxedProps extends StoryblokBase {
    signage: boolean,
    children: StoryblokBase[]
}

export const Content = styled.div`
    grid-column: 2;
    position: relative;
    z-index: 1;
    overflow: hidden;
`

const ContainerBoxed = (props: StoryblokComponentProps<ContainerBoxedProps>) => {
    const { blok } = props;

    return (
        <ContainerBoxedRelative>
            <Content>
                {blok.children.map((blok) => React.createElement(Components(blok.component), { key: blok._uid, blok: blok, location: props.location }))}
            </Content>
            {blok.signage && <SignageImage />}
        </ContainerBoxedRelative>
    )
}

export default ContainerBoxed