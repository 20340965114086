import React from "react";
import { Button } from "./Button";
import { useApplicationCtx } from "./ApplicationCtx";

export const Nope = () => {
  const { reset } = useApplicationCtx();

  return (
    <>
      <h2>Leider passt das nicht 😔</h2>

      <p>
        Wie es scheint, passt du leider nicht auf die von uns ausgeschriebene
        Stelle.
      </p>

      <p>
        <strong>
          Wir bedanken uns sehr für dein Interesse an Ziegler's Backstube.
        </strong>
      </p>

      <p>Vielleicht passt es ja das nächste Mal - bis dann!</p>

      <Button onClick={reset}>Neu starten</Button>
    </>
  );
};
