import Page from "./Layout/Page";
import ComponentNotFound from "./ComponentNotFound";
import Job from "./Job/Job";
import MainSlider from "./Slider/MainSlider";
import NavItem from "./Layout/NavItem";
import EazyCard from "./EazyCard/EazyCard";
import { LandingGrid, MobileLandingGrid } from "./LandingGrid/LandingGrid";
import FeatureText from "./FeatureText/FeatureText";
import FooterNavItem from "./Layout/FooterNavItem";
import LunchTable from "./Mittagstisch/Mittagstisch";
import SwipeMenu from "./SwipeMenu/SwipeMenu";
import AlternateColumnGrid from "./AlternateColumnGrid/AlternateColumnGrid";
import CommonReference from "./CommonReference/CommonReference";
import RichText from "./RichText/RichText";
import UnderOverlineText from "./UnderOverlineText/UnderOverlineText";
import Accordion from "./Accordion/Accordion";
import TwoColumnGrid from "./TwoColumnGrid/TwoColumnGrid";
import OneColumnGrid from "./OneColumnGrid/OneColumnGrid";
import ContainerBoxed from "./ContainerBoxed/ContainerBoxed";
import Spacing from "./Spacing/Spacing";
import IconGrid from "./IconGrid/IconGrid";
import Filiale from "./Filiale/Filiale";
import ThreeImageGrid from "./ThreeImageGrid/ThreeImageGrid";
import FeatureHeadline from "./FeatureHeadline/FeatureHeadline";
import LocationGrid from "./LocationGrid/LocationGrid";
import SwipeMenuReference from "./SwipeMenu/SwipeMenuReference";
import TwoColumnTable from "./TwoColumnTable/TwoColumnTable";
import Product from "./Product/Product";
import ProductFilter from "./ProductFilter/ProductFilter";
import ProductPage from "./ProductPage/ProductPage";
import JobGrid from "./JobGrid/JobGrid";
import ApplicationForm from "./ApplicationForm/ApplicationForm";
import Deploy from "./Deploy/Deploy";
import BigLogo from "./Layout/BigLogo";
import Application from "./Application/Application";

const ComponentList: { [key: string]: any } = {
  page: Page,
  underOverlineText: UnderOverlineText,
  job: Job,
  slider: MainSlider,
  headerNavLink: NavItem,
  eazyCard: EazyCard,
  landingGrid: LandingGrid,
  mobileLandingGrid: MobileLandingGrid,
  featureText: FeatureText,
  footerNavLink: FooterNavItem,
  lunchTable: LunchTable,
  swipeMenu: SwipeMenu,
  swipe_menu_reference: SwipeMenuReference,
  alternateColumnGrid: AlternateColumnGrid,
  common_reference: CommonReference,
  richText: RichText,
  accordion: Accordion,
  twoColumnGrid: TwoColumnGrid,
  oneColumnGrid: OneColumnGrid,
  containerBoxed: ContainerBoxed,
  spacing: Spacing,
  iconGrid: IconGrid,
  filiale: Filiale,
  threeImageGrid: ThreeImageGrid,
  featureHeadline: FeatureHeadline,
  locationGrid: LocationGrid,
  twoColumnTable: TwoColumnTable,
  bread: ProductPage,
  bun: ProductPage,
  snack: ProductPage,
  cake: ProductPage,
  product: Product,
  productFilter: ProductFilter,
  jobGrid: JobGrid,
  applicationForm: ApplicationForm,
  deploy: Deploy,
  bigLogo: BigLogo,
  application: Application,
};

const Components = (type: string) => {
  if (typeof ComponentList[type] === "undefined") {
    return ComponentNotFound;
  }
  return ComponentList[type];
};

export default Components;
