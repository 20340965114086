import React from "react";

export type PageKeys =
  | "entry"
  | "p1-who-we-are"
  | "p2-benefits"
  | "p3-what-else"
  | "p4-your-day"
  | "q1-qualifications"
  | "q2-experience"
  | "q3-locality"
  | "q4-start"
  | "nope"
  | "yope";

export type CtxProps = {
  page: PageKeys;
  goTo: (page: PageKeys) => void;
  answers: Map<string, string>;
  selectAnswer: (key: string, value: string) => void;
  reset: () => void;
};

export const ApplicationCtx = React.createContext<CtxProps>({} as CtxProps);
export const useApplicationCtx = () => React.useContext(ApplicationCtx);
