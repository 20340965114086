import * as React from 'react'
import { withTheme } from 'styled-components'
import Link from 'gatsby-link'


import LogoDark from './../../images/LogoDark.svg'
import LogoLight from './../../images/LogoLight.svg'
import { ThemeType } from './Themes';


interface LogoProps {
    type?: string;
    height: string;
    theme: ThemeType;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;

}

const Logo: React.FC<LogoProps> = (props) => {
    let logoType = ""
    if (props.type) {
        logoType = props.type
    } else {
        logoType = props.theme.name;
    }

    return (
        <Link onClick={props.onClick} to="/">
            <img src={logoType === "light" ? LogoDark : LogoLight} height={props.height} alt="Ziegler Logo" />
        </Link>
    )
}

export default withTheme(Logo);