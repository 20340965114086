import React from 'react'
import Switch from "react-switch"

const ThemeToggler = ({ theme, toggleTheme }) => {
    return (
        <Switch
            aria-label="switch"
            role="switch"
            className="rotate"
            height={15}
            width={30}
            checkedIcon={false}
            onColor={"#4C4C4C"}
            offColor={"#4C4C4C"}
            offHandleColor={"#fff"}
            onHandleColor={"#000"}
            uncheckedIcon={false}
            onChange={() => toggleTheme()}
            checked={theme === 'dark' ? true : false} />
    )
}

export default ThemeToggler