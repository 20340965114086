import React from 'react'

import { IconProps } from './index'

export const LocationMarker = (props: IconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill={props.color ? props.color : "#c8b482"} className={props.className} height={props.height ? props.height : 40.97} viewBox="0 0 27.79 40.97" width={props.width ? props.width : 27.79} >
            <path d="M18.56,13.93a4.67,4.67,0,1,1-4.67-4.68,4.68,4.68,0,0,1,4.67,4.68m8.73,0a13.4,13.4,0,1,0-26.79,0c0,7.56,10.6,22.49,10.6,22.49l2.79,3.73s13.4-16.79,13.4-26.22" fill="#c8b482" /><path d="M13.88,41,10.7,36.72C10.26,36.09,0,21.56,0,13.94a13.9,13.9,0,1,1,27.79,0c0,9.48-12.95,25.83-13.5,26.52Zm0-40A12.93,12.93,0,0,0,1,13.94c0,7.31,10.41,22,10.51,22.19l2.39,3.2c2.22-2.88,12.89-17.16,12.89-25.39A12.93,12.93,0,0,0,13.89,1Zm0,18.12a5.19,5.19,0,1,1,5.17-5.18A5.19,5.19,0,0,1,13.89,19.12Zm0-9.37a4.19,4.19,0,1,0,4.17,4.19A4.18,4.18,0,0,0,13.89,9.75Z" fill="#fff" />
        </svg >
    )
}