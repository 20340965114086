import * as React from 'react'
import styled from 'styled-components'

const CopyrightStyle = styled.p`
    color: ${({ theme }) => theme.colors.fonts.copyright};
    text-align: center;
    margin-bottom: 24px;
`

interface CopyrightProps {
    text: string;
}

const Copyright = (props: CopyrightProps) => {
    return (
        <CopyrightStyle>{props.text}</CopyrightStyle>
    )
}

export default Copyright