import * as React from 'react'
import styled from 'styled-components'

import { StoryblokBase, StoryblokComponentProps } from '../../types'
import Components from '../Components'

const ItemsGrid = styled.div`
    grid-column: 2;
`

interface OneColumnGridProps extends StoryblokBase {
    children: StoryblokBase[]
}

const OneColumnGrid = (props: StoryblokComponentProps<OneColumnGridProps>) => {
    const { blok } = props;
    return (
        <ItemsGrid>
            {blok.children && blok.children.map((blok) => React.createElement(Components(blok.component), { key: blok._uid, blok: blok }))}
        </ItemsGrid>
    )
}

export default OneColumnGrid