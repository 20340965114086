import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { mediaQueries } from "../Layout/Themes";
import { ApplicationCtx, CtxProps, PageKeys } from "./ApplicationCtx";
import { Benefits } from "./Benefits";
import { Experience } from "./Experience";
import { Locality } from "./Locality";
import { Nope } from "./Nope";
import { Qualifications } from "./Qualifications";
import { Start } from "./Start";
import { WhatElse } from "./WhatElse";
import { WhoWeAre } from "./WhoWeAre";
import { Yope } from "./Yope";
import { YourDay } from "./YourDay";
import { Entry } from "./Entry";

const PageContainer = styled.div`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0 0.5rem 8rem 0.5rem;

  ${mediaQueries("Tablet")`
    max-width: 75%;
  `};

  > ul {
    list-style-position: inside;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Pages: { [key in PageKeys]: JSX.ElementType } = {
  entry: Entry,
  "p1-who-we-are": WhoWeAre,
  "p2-benefits": Benefits,
  "p3-what-else": WhatElse,
  "p4-your-day": YourDay,
  "q1-qualifications": Qualifications,
  "q2-experience": Experience,
  "q3-locality": Locality,
  "q4-start": Start,
  nope: Nope,
  yope: Yope,
};

const firstPage: PageKeys = "p1-who-we-are";

const Application = () => {
  const [page, setPage] = React.useState<PageKeys>(firstPage);
  const [answers, setAnswers] = React.useState(() => new Map<string, string>());

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Page = Pages[page];

  const selectAnswer: CtxProps["selectAnswer"] = (key, value) => {
    const newAnswers = new Map(answers);
    newAnswers.set(key, value);
    setAnswers(newAnswers);
  };

  const goTo: CtxProps["goTo"] = (p) => {
    setPage(p);
    window.scrollTo(0, 0);
  };

  const reset: CtxProps["reset"] = () => {
    setPage(firstPage);
    setAnswers(new Map<string, string>());
  };

  return (
    <>
      <ApplicationCtx.Provider
        value={{ page, goTo, answers, selectAnswer, reset }}
      >
        <PageContainer>
          <Page />
        </PageContainer>
      </ApplicationCtx.Provider>
    </>
  );
};

export default Application;
