import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div<{ borderTop: boolean }>`
    display: flex;
    flex-direction: column;
    border-top: ${({ borderTop, theme }) => borderTop ? theme.name === "light" ? "1px solid " + theme.colors.colors.Black : "1px solid " + theme.colors.colors.Beige : "none"};
    border-bottom: 1px solid ${({ theme }) => theme.name === "light" ? theme.colors.colors.Black : theme.colors.colors.Beige};
`

const Title = styled.h2`
    text-transform: "uppercase";
    margin: 0;
    margin-right: 0.5rem;
    text-align: left;
`

const Description = styled.span`
    padding-left: 12px;
    font-family:  ${({ theme }) => theme.font.headings.family};
    font-weight:  ${({ theme }) => theme.font.headings.weight.regular};
    font-size: 1rem;
`

interface JobListItemProps {
    borderTop: boolean,
    title: string,
    subTitle: string,
    description: string,
    type: string
}

const ListItemContainer = styled.div`
    display: flex;
    align-items: center;
    background: ${({ theme }) => theme.colors.backgrounds.body};
    border: 0;
    padding: 12px;
    outline: none;
`

export const ApplicationButton = styled(Link)`
    margin-left: auto;
    text-decoration: none;
    border-radius: 4px;
    font-size: 1rem;
    padding: 4px 36px;
    text-transform: uppercase;
    transition: all 0.3s;
    font-family: ${({ theme }) => theme.font.body.family};
    font-weight: ${({ theme }) => theme.font.body.weight.semiBold};
    background: ${({ theme }) => theme.name === 'light' ? '#000' : '#fff'};
    color: ${({ theme }) => theme.name === 'light' ? '#fff' : '#000'};
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`

const JobListItem = (props: JobListItemProps) => {
    return (
        <Wrapper borderTop={props.borderTop}>
            <ListItemContainer>
                <Title>{props.title}<span style={{ textTransform: "none" }}> {props.subTitle && props.subTitle}</span></Title>
                {
                    props.description && <Description>{props.description}</Description>
                }
                <ApplicationButton to={'/karriere/aktuelle-stellen/online-bewerbung?job=' + props.title + ' ' + props.subTitle + '&desc=' + props.description + '&type=' + props.type}> Jetzt bewerben </ApplicationButton>
            </ListItemContainer>
        </Wrapper>
    )
}

export default JobListItem