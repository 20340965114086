import React from 'react'
import styled from 'styled-components'
import { StoryblokBase, StoryblokComponentProps } from '../../types'

import { LocationHeadline } from '../Icons'
import { UnderOverlineStyle } from '../UnderOverlineText/UnderOverlineText'


export const LocationHeadlineStyle = styled(UnderOverlineStyle)`
    position: relative;
`

export const LocationHeadlineIcon = styled(LocationHeadline)`
    position: relative;
    bottom: -6px;
    padding-right: 8px;
`

const Container = styled.div`
    text-align: center;
    white-space: pre-wrap;
    padding: 0 8px;
`

export interface FeatureHeadlineProps extends StoryblokBase {
    underOverlineText: string,
    showLocationIcon: boolean,
    heading: string,
    subHeading: string
}

const FeatureHeadline = (props: StoryblokComponentProps<FeatureHeadlineProps>) => {
    const { blok } = props;
    return (
        <Container>
            <LocationHeadlineStyle size={1.5}>
                {blok.showLocationIcon && <LocationHeadlineIcon />} {blok.underOverlineText}
            </LocationHeadlineStyle>
            <h1>{blok.heading}</h1>
            <p>{blok.subHeading}</p>
        </Container>
    )
}

export default FeatureHeadline