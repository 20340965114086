import { navigate } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import { Chevron } from '../Icons'

const GoBackButton = styled.div`
    display: flex;
    align-items: center;
    font-family: ${({ theme }) => theme.font.headings.family};
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: ${({ theme }) => theme.font.headings.weight.regular};
    margin: 3rem 0;
    cursor: pointer;
    transition: all 0.30s linear;
    :hover {
        opacity: 0.6;
    }
    svg {
        fill: ${({ theme }) => theme.colors.fonts.body};
    }
`

const BackButtonIcon = styled(Chevron)`
    transform: rotate(90deg);
`

const BackButton = () => {
    const goBack = () => {
        navigate(-1);
    }
    return (
        <GoBackButton onClick={goBack}>
            <BackButtonIcon height={26} /> Zurück zur Übersicht
        </GoBackButton>
    )
}

export default BackButton