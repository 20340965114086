import React, { useState } from 'react'
import styled from 'styled-components'
import { FilialeProps } from '../Filiale/Filiale'
import { LocationMarker } from '../Icons'
import LocationCard from '../LocationGrid/LocationCard'

const MARKER_HEIGHT = 30

export interface MarkerProps {
    filiale: FilialeProps,
    slug: string,
    $hover?: boolean,
    uuid?: string
}


const MarkerCard = styled(LocationCard) <{ visible: boolean }>`
    position: absolute;
    width: 220px;
    height: auto;
    top: -185px;
    left: -10px;
    z-index: 12;
    transition-property: opacity;
    transition-duration: 0.2s;
    transition-delay: 0.2s;
    opacity: ${({ visible }) => visible ? 1 : 0};
    &:after {
        content:'';
        position: absolute;
        top: 100%;
        left: 14px;
        width: 0;
        height: 0;
        border-top: solid 10px #fff;
        border-left: solid 10px transparent;
        border-right: solid 10px transparent;
    }

    &:hover {
        transform: unset;
    }
`

const MarkerWrapper = styled.a`
    position: relative;
    display: inline-block;
    top: -${MARKER_HEIGHT}/2;
    left: -${MARKER_HEIGHT}/2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 600;
    white-space: nowrap;
    cursor: pointer;
    text-decoration: none;
    color: #000;
`

const Marker = (props: MarkerProps) => {
    return (
        <MarkerWrapper>
            <LocationMarker height={MARKER_HEIGHT} />
            <MarkerCard
                image={props.filiale.previewImage}
                title={props.filiale.title}
                showIcon={false}
                visible={props.$hover}
            />
        </MarkerWrapper>
    )
}

export default Marker