import * as React from 'react'

import { StoryblokBase, StoryblokComponentProps, StoryblokLink } from '../../types';
import { ContainerBoxed, GridItem, Column2 } from '../Layout/Grid';
import styled, { css } from 'styled-components';

import SignageGold from './SignageGold.svg'
import { Link } from 'gatsby';

interface FeatureTextProps extends StoryblokBase {
    text: string,
    link: StoryblokLink
}

const StyledLink = styled(Link)`
    text-decoration: none;
    display: block;
`

const FeatureTextGrid = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;
    border-top: 1px solid ${({ theme }) => theme.colors.colors.Beige};
    border-bottom: 1px solid ${({ theme }) => theme.colors.colors.Beige};
    padding: 8px;
    margin: 16px 0;
    align-items: center;
`

const Signage = styled.img`
    width: 48px;
    height: auto;
`

const BasicTextStyle = css`
    font-family: ${({ theme }) => theme.font.headings.family};
    font-size: 1.5rem;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.colors.Beige};
`

const Text = styled.span`
    ${BasicTextStyle}
    font-weight: ${({ theme }) => theme.font.headings.weight.regular};
`

const Plus = styled.span`
    ${BasicTextStyle}
    font-weight: ${({ theme }) => theme.font.headings.weight.medium};
`

const FeatureText = (props: StoryblokComponentProps<FeatureTextProps>) => {
    const { blok } = props;

    return (
        <ContainerBoxed>
            <Column2>
                <StyledLink to={blok.link?.cached_url}>
                    <FeatureTextGrid>
                        <GridItem gridRowStart={1} gridRowEnd={1} gridColumnStart={1} gridColumnEnd={2}>
                            <Plus>+++</Plus> <Text>{props.blok.text}</Text> <Plus>+++</Plus>
                        </GridItem>
                        <GridItem gridRowStart={1} gridRowEnd={1} gridColumnStart={2} gridColumnEnd={3} justifySelf={"end"}>
                            <Signage src={SignageGold} alt="Ziegler´s Backstube" />
                        </GridItem>
                    </FeatureTextGrid>
                </StyledLink>
            </Column2>
        </ContainerBoxed>
    )
}

export default FeatureText;