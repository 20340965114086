import styled from "styled-components";

export const Button = styled.button.attrs({ type: "button" })`
  border-radius: 4px;
  font-size: 1rem;
  padding: 4px 36px;
  text-transform: uppercase;
  transition: all 0.3s;
  font-family: ${({ theme }) => theme.font.body.family};
  font-weight: ${({ theme }) => theme.font.body.weight.semiBold};
  cursor: pointer;
  margin-top: 4px;
  background: ${({ theme }) => theme.colors.backgrounds.body};
  color: ${({ theme }) => theme.colors.fonts.body};
  border: 1px solid ${({ theme }) => theme.colors.fonts.body};
  &:hover {
    opacity: 0.8;
  }
  &[disabled] {
    opacity: 0.2;
    cursor: unset;
  }
`;
