import * as React from "react";
import styled, { ThemeContext } from "styled-components";

import { StoryblokBase, StoryblokComponentProps } from "../../types";
import {
  Column2,
  GridItem,
  Spacing,
  ContainerBoxedRelative,
} from "../Layout/Grid";
import BorderText from "../BorderText/BorderText";
import MittagstischDark from "./MittagstischDark.png";
import MittagstischLight from "./MittagstischLight.png";
import { useContext } from "react";
import { mediaQueries } from "../Layout/Themes";
import { getStoryblokClient } from "../storyBlokClient";
import { RichTextProps } from "../RichText/RichText";

interface LunchTableProps extends StoryblokBase {
  title: string;
  text: RichTextProps;
  lunchCards: LunchCard[];
}

interface LunchCard {
  _uid: string;
  title: string;
  date: string;
  info: string;
  lunchDays: LunchDay[];
  extraLunch: LunchDay[];
}

interface LunchDay {
  _uid: string;
  day: string;
  lunches: Lunch[];
}

interface Lunch {
  _uid: string;
  title: string;
  price: string;
}

const LunchCardGrid = styled.div<{ rows: number }>`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: ${({ rows }) => rows}fr;
  margin-bottom: 2rem;
`;

const Title = styled.h1<{ size: string }>`
  padding-top: 1.5rem;
  font-family: ${({ theme }) => theme.font.headings.family};
  font-weight: ${({ theme }) => theme.font.headings.weight.medium};
  font-size: ${({ size }) => size};
  line-height: ${({ size }) => size};
  white-space: pre-line;
  text-transform: uppercase;
  margin: 0;
`;

const Date = styled(Title)`
  padding-top: 8px;
`;

const Info = styled(Title)`
  font-weight: ${({ theme }) => theme.font.headings.weight.regular};
  padding-top: 2px;
`;

const LunchDay = styled.div`
  font-size: 1.2rem;
  padding-top: 1.5rem;
  font-weight: ${({ theme }) => theme.font.body.weight.semiBold};
`;

const Price = styled.div`
  font-weight: ${({ theme }) => theme.font.body.weight.semiBold};
  text-align: right;
`;
const LunchTitle = styled.div`
  margin-bottom: 0.5rem;
`;

const SignageImage = styled.img`
  position: absolute;
  z-index: 1;
  bottom: 0px;
  right: 60px;
  height: 480px;
  width: auto;
`;

const Wrapper = styled(Column2)`
  ${mediaQueries("Mobile")`
        padding: 0 8px;
    `};

  ${mediaQueries("Tablet")`
        padding: 0;
    `};
`;

interface RenderLunchDayProps {
  lunchDay: LunchDay;
}

const RenderLunchDay: React.FC<RenderLunchDayProps> = (props) => {
  const { lunchDay } = props;
  return (
    <React.Fragment>
      <LunchDay>{lunchDay.day}</LunchDay>
      <div></div>
      {lunchDay.lunches.map((lunch) => (
        <React.Fragment key={lunch._uid}>
          <LunchTitle>{lunch.title}</LunchTitle>
          <Price>{lunch.price}</Price>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

const Divider = styled.hr`
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  border-width: 0;
  border-top: 1px solid ${({ theme }) => theme.colors.fonts.body};
  border-radius: 0;
  width: 100%;
`;

const LunchCardWrapper = styled.div`
  display: grid;
  column-gap: 6rem;

  ${mediaQueries("Mobile")`
        grid-template-columns: 1fr;
    `};

  ${mediaQueries("Tablet")`
        grid-template-columns: 1fr 1fr;
    `};
`;

const RichTextContainer = styled.div`
  ul {
    p {
      margin: 0;
    }
  }
`;

const LunchTable = (props: StoryblokComponentProps<LunchTableProps>) => {
  const { blok } = props;
  const storyBlok = getStoryblokClient();
  const themeContext = useContext(ThemeContext);

  return (
    <ContainerBoxedRelative>
      <Wrapper>
        <Spacing size={8} />
        <BorderText>{blok.title}</BorderText>
        <RichTextContainer
          dangerouslySetInnerHTML={{
            __html: storyBlok.richTextResolver.render(blok.text),
          }}
        />
        <Spacing size={4} />
        <LunchCardWrapper>
          {blok.lunchCards.map((lunchCard) => (
            <LunchCardGrid
              key={lunchCard._uid}
              rows={lunchCard.lunchDays.length + 1}
            >
              <GridItem
                gridColumnStart={1}
                gridColumnEnd={2}
                gridRowStart={1}
                gridRowEnd={2}
              >
                <Title size={"2rem"}>{lunchCard.title}</Title>
                <Date size={"1.5rem"}>{lunchCard.date}</Date>
                <Info size={"1.2rem"}>{lunchCard.info}</Info>
                <Spacing size={2} />
              </GridItem>
              <GridItem
                gridColumnStart={2}
                gridColumnEnd={3}
                gridRowStart={1}
                gridRowEnd={2}
              ></GridItem>
              {lunchCard.lunchDays.map((lunchDay) => (
                <RenderLunchDay key={lunchDay._uid} lunchDay={lunchDay} />
              ))}
              {lunchCard.extraLunch ? (
                <React.Fragment>
                  <Divider />
                  <Divider />
                  {lunchCard.extraLunch.map((lunchDay) => (
                    <RenderLunchDay key={lunchDay._uid} lunchDay={lunchDay} />
                  ))}
                </React.Fragment>
              ) : (
                <React.Fragment />
              )}
            </LunchCardGrid>
          ))}
        </LunchCardWrapper>
        <Spacing size={8} />
      </Wrapper>
      {blok.title === "Mittagstisch" && (
        <SignageImage
          src={
            themeContext.name === "dark" ? MittagstischDark : MittagstischLight
          }
          alt="Ziegler`s Mittagstisch"
        />
      )}
    </ContainerBoxedRelative>
  );
};

export default LunchTable;
