import * as React from 'react'
import styled from 'styled-components';

import { StoryblokBase, StoryblokComponentProps, StoryblokLink } from '../../types';
import ZieglerCard from './Ziegler_Card.png'
import { Column2 } from '../Layout/Grid';
import OutlineButton from '../Buttons/OutlineButton';
import { mediaQueries } from '../Layout/Themes';

interface EazyCardProps extends StoryblokBase {
    text: string;
    button: {
        text: string,
        link: StoryblokLink
    };
}

const MaxHeightContainer = styled.div`
    display: grid;
    margin-top: 48px;
    padding: 0.5rem 0;
    background-color: ${({ theme }) => theme.colors.colors.Grey4};
    grid-template-columns: 0 auto 0;

    ${mediaQueries("Tablet")`
            grid-template-columns: 15% auto 15%;
    `};

    ${mediaQueries("Laptop")`
        grid-template-columns: 20% auto 20%;
    `};

    ${mediaQueries("Desktop")`
        grid-template-columns: 30% auto 30%;
    `};
`

const ItemsGrid = styled.div`
    position: relative;
    display: grid;
    justify-items: center;
    align-items: center;
    min-height: 3rem;
    ${mediaQueries("Mobile")`
        grid-template-columns: 1fr 1fr;
    `};

    ${mediaQueries("Tablet")`
        grid-template-columns: 1fr 1fr 1fr;
    `};
`

const EazyCardImage = styled.img`
    display: inline;
    position: absolute;

    ${mediaQueries("Mobile")`
        width: 200px;
        height: auto;
        top: -40px;
        left: 0;
    `};

    ${mediaQueries("Tablet")`
        width: auto;
        height: 160px;
        top: -50px;
        left: 0;
    `};
`

const Text = styled.span`
    font-family: ${({ theme }) => theme.font.headings.family};
    font-weight: ${({ theme }) => theme.font.headings.weight.medium};
    font-size: 2rem;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.fonts.white};

    ${mediaQueries("Mobile")`
        display: none;
    `};

    ${mediaQueries("Tablet")`
        display: block;
    `};
`
const EazyCardButton = styled(OutlineButton)`
    ${mediaQueries("Mobile")`
        padding: 0;
    `};

    ${mediaQueries("Tablet")`
         padding: .175rem 2rem;
    `};
`

const EazyCard = (props: StoryblokComponentProps<EazyCardProps>) => {
    const { blok } = props;
    const button = blok.button[0];

    return (
        <MaxHeightContainer>
            <Column2>
                <ItemsGrid>
                    <div>
                        <EazyCardImage src={ZieglerCard} alt="Die Ziegler Card" />
                    </div>
                    <Text>{blok.text}</Text>
                    {
                        button ? <EazyCardButton link={button.link} text={button.text} /> :
                            <div>Please add a Button</div>
                    }
                </ItemsGrid>
            </Column2>
        </MaxHeightContainer>
    )
}

export default EazyCard;