import Storyblok from "storyblok-js-client"

import config from '../../gatsby-config'

export const getStoryblokClient = () => {
    let sbConfigs = config.plugins.filter((item) => {
        return item.resolve === 'gatsby-source-storyblok'
    })
    let sbConfig = sbConfigs.length > 0 ? sbConfigs[0] : {}
    const storyblok = new Storyblok({
        accessToken: sbConfig.options.accessToken
    })

    return storyblok;
}