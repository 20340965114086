import React from 'react'
import styled from 'styled-components'

import Icon from './Icon'
import { StoryblokBase, StoryblokComponentProps } from '../../types'

import { AussenSitzplaetze } from './AussenSitzplaetze'
import { BehindertenGerecht } from './BehindertenGerecht'
import { Fruehstueck } from './Fruehstueck'
import { Eis } from './Eis'
import { InnenSitzplaetze } from './InnenSitzplaetze'
import { Kartenzahlung } from './Kartenzahlung'
import { Mittagstisch } from './Mittagstisch'
import { Wlan } from './Wlan'
import { Zapfanlage } from './Zapfanlage'


export interface IconGridProps extends StoryblokBase {
    wlan: boolean,
    behindertenGerecht: boolean,
    innenSitzplaetze: boolean,
    aussenSitzplaetze: boolean,
    mittagstisch: boolean,
    kartenzahlung: boolean,
    fruehstueck: boolean,
    eis: boolean,
    zapfanlage: boolean
}

const Wrapper = styled.div`
    margin-top: 6rem;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(150px, 1fr));
    grid-auto-flow: row dense;
    grid-row-gap: 4rem;
`

const IconGrid = (props: StoryblokComponentProps<IconGridProps>) => {
    const { blok } = props;

    return (
        blok ?
            <Wrapper>
                <Icon text="Kostenloses WLAN" visibility={blok.wlan}><Wlan /></Icon>
                <Icon text="Behindertengerecht" visibility={blok.behindertenGerecht}> <BehindertenGerecht /></Icon>
                <Icon text="Innensitzplätze" visibility={blok.innenSitzplaetze} > <InnenSitzplaetze /></Icon>
                <Icon text="Außensitzplätze" visibility={blok.aussenSitzplaetze} > <AussenSitzplaetze /></Icon>
                <Icon text="Mittagstisch Menü" visibility={blok.mittagstisch} > <Mittagstisch /></Icon>
                <Icon text="Kartenzahlung" visibility={blok.kartenzahlung} > <Kartenzahlung /></Icon>
                <Icon text="Frühstück" visibility={blok.fruehstueck} > <Fruehstueck /></Icon>
                <Icon text="Selbstgemachtes Eis" visibility={blok.eis} > <Eis /></Icon>
                <Icon text="Frisch gezapftes Bier" visibility={blok.zapfanlage} > <Zapfanlage /></Icon>
            </Wrapper> :
            <React.Fragment />
    )
}

export default IconGrid