import * as React from 'react'
import styled, { css } from 'styled-components';

import { StoryblokBase, StoryblokComponentProps, StoryblokLink } from '../../types';
import { ContainerBoxed } from '../Layout/Grid';
import { Link } from 'gatsby';

export interface SwipeMenuProps extends StoryblokBase {
    menuItems: MenuItem[];
}

export interface MenuItem {
    name: string;
    link: StoryblokLink;
}

const MenuItemSelectedStyle = css`
    border-top: ${({ theme }) => "1px solid " + theme.colors.swipeMenu.selected};
    border-bottom: ${({ theme }) => "1px solid " + theme.colors.swipeMenu.selected};
        color: ${({ theme }) => theme.colors.swipeMenu.selected};
    &:hover {
            color: ${({ theme }) => theme.colors.swipeMenu.selected};
            cursor: default;
    }
`

const MenuItemStyle = css`
    font-size: 1.1rem;
    font-weight: ${({ theme }) => theme.font.headings.weight.regular};
    font-family:    ${({ theme }) => theme.font.headings.family};
    text-transform: uppercase;
    display: inline-block;
    vertical-align: middle;
    padding: 0 0.25rem;
    margin: 0 1rem;
    cursor: pointer;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.swipeMenu.color};
    transition: all 0.30s linear;
    border-top: ${({ theme }) => "1px solid transparent"};
    border-bottom: ${({ theme }) => "1px solid transparent"};
    &:hover {
            color: ${({ theme }) => theme.colors.swipeMenu.hover};
    }
    &.active {
        ${MenuItemSelectedStyle}
    }
`

const MenuItem = styled(Link)`
    ${MenuItemStyle}
`;

const MenuWrapper = styled.div`
    grid-column: 2;
    position: relative;
    z-index: 20;
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    ::-webkit-scrollbar {
        display: none;
    }
    max-width: 100%;
`

const ContainerBoxedStyled = styled(ContainerBoxed)`
    height: 80px;
    background: ${({ theme }) => theme.colors.swipeMenu.background};
    justify-items: center;
    align-items: center;
    max-width: 100%;
    position: -webkit-sticky; /* Safari & IE */
    position: sticky;
    top: 80px;
    z-index: 40;
`

const SwipeMenu = (props: StoryblokComponentProps<SwipeMenuProps>) => {
    const { blok } = props;

    return (
        blok && blok.menuItems ?
            < ContainerBoxedStyled >
                < MenuWrapper >
                    {
                        blok.menuItems.map((element, index) =>
                            <MenuItem to={"/" + element.link.cached_url} activeClassName={"active"} partiallyActive={false} key={"swipemenu- " + index}>
                                {element.name}
                            </MenuItem>
                        )
                    }
                </MenuWrapper >
            </ContainerBoxedStyled > :
            <React.Fragment />
    )
}

export default SwipeMenu;