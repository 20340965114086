import React from "react";
import styled from "styled-components";
import { PageKeys, useApplicationCtx } from "./ApplicationCtx";

const RadioGroupStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 2rem;
`;

const RadioStyle = styled.button`
  border-radius: 4px;
  font-size: 1rem;
  padding: 4px 36px;
  font-family: ${({ theme }) => theme.font.body.family};
  font-weight: ${({ theme }) => theme.font.body.weight.semiBold};
  cursor: pointer;
  background: ${({ theme }) => theme.colors.colors.Beige2};
  color: ${({ theme }) => theme.colors.fonts.body};
  border: none;
  width: 100%;
  height: 4rem;
  &:hover {
    opacity: 0.8;
  }

  &[data-selected="true"] {
    outline: 3px solid ${({ theme }) => theme.colors.colors.Beige};
  }
`;

const RadioContext = React.createContext("");

type Props = {
  label: string;
  onClick?: () => void;
};

export const RadioButton = ({ label, onClick }: Props) => {
  const { answers, selectAnswer } = useApplicationCtx();
  const answerKey = React.useContext(RadioContext);

  const isSelected = answers.get(answerKey) === label;

  return (
    <RadioStyle
      onClick={() => {
        selectAnswer(answerKey, label);
        onClick?.();
      }}
      data-selected={isSelected}
      role="radio"
      aria-checked={isSelected}
    >
      {label}
    </RadioStyle>
  );
};

type GroupProps = {
  groupKey: string;
  children: React.ReactNode;
};

export const RadioGroup = ({ groupKey, children }: GroupProps) => {
  return (
    <RadioContext.Provider value={groupKey}>
      <RadioGroupStyle role="radiogroup">{children}</RadioGroupStyle>
    </RadioContext.Provider>
  );
};
