import React from "react";
import { useApplicationCtx } from "./ApplicationCtx";
import { Button } from "./Button";
import { Img } from "./Img";
import { data } from "./data";

export const YourDay = () => {
  const { answers, goTo } = useApplicationCtx();

  const position = answers.get("Stelle");
  const yourDay = data.find((x) => x.position === position)?.yourDay;
  const img = data.find((x) => x.position === position)?.image3;

  return (
    <>
      <h2>
        <span>So sieht dein perfekter Tag bei uns aus</span>
        <br />
        <span>(Nach umfangreicher Einarbeitung)</span>
      </h2>

      <Img src={img} />

      <ul>
        {yourDay?.map((x) => (
          <li key={x}>{x}</li>
        ))}
      </ul>

      <Button onClick={() => goTo("q1-qualifications")}>
        <span>Das hört sich gut an!</span>
        <br />
        <span>Passe ich ins Team/ zu Ziegler's?</span>
      </Button>

      <p>
        Du hast in manchen Bereichen noch keine Erfahrung❓ Kein Problem‼️ Wir
        bringen es dir bei 💯
      </p>
    </>
  );
};
