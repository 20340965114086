import * as React from 'react'
import styled from 'styled-components'

import { StoryblokBase, StoryblokComponentProps } from '../../types'
import Components from '../Components'
import { mediaQueries } from '../Layout/Themes'

const ItemsGrid = styled.div`
    display: grid;
    grid-column: 2;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    ${mediaQueries("Mobile")`
        grid-template-columns: 1fr;
    `};

    ${mediaQueries("Tablet")`
        grid-template-columns: 1fr 1fr;
    `};
`

const DivRelative = styled.div`
    position: relative;
`

interface TwoColumnGridProps extends StoryblokBase {
    leftColumn: StoryblokBase[],
    rightColumn: StoryblokBase[]
}

const TwoColumnGrid = (props: StoryblokComponentProps<TwoColumnGridProps>) => {
    const { blok } = props;
    return (
        <ItemsGrid>
            <DivRelative>
                {
                    blok.leftColumn && blok.leftColumn.map((blok) => React.createElement(Components(blok.component), { key: blok._uid, blok: blok }))
                }
            </DivRelative>
            <DivRelative>
                {
                    blok.rightColumn && blok.rightColumn.map((blok) => React.createElement(Components(blok.component), { key: blok._uid, blok: blok }))
                }
            </DivRelative>
        </ItemsGrid>
    )
}

export default TwoColumnGrid