import * as React from 'react'
import styled from 'styled-components'
import Link from 'gatsby-link'

import { StoryblokBase, StoryblokComponentProps, StoryblokLink } from '../../types'
import { mediaQueries } from './Themes'

const FooterNavItemStyle = styled(Link)`
    font-size: 1rem;
    font-weight: ${({ theme }) => theme.font.headings.weight.medium};
    font-family: ${({ theme }) => theme.font.body.family};
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.footer.navLink};
    transition: all 0.30s linear;
    &:hover {
        opacity: 0.8;
    }
    text-decoration: none;

    ${mediaQueries("Mobile")`
            padding: 8px 16px;
    `};

    ${mediaQueries("Tablet")`
        padding: 0 16px;
    `};
`

interface FooterNavItemProps extends StoryblokBase {
    name: string,
    link: StoryblokLink
}

const FooterNavItem = (props: StoryblokComponentProps<FooterNavItemProps>) => {
    const { blok } = props;

    return (
        <FooterNavItemStyle to={"/" + blok.link.cached_url}>
            {blok.name}
        </FooterNavItemStyle>
    )
}

export default FooterNavItem