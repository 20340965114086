import * as React from 'react'

import Facebook from './Facebook.svg'
import Instagram from './Instagram.svg'
import styled from 'styled-components'

const SocialIcon = styled.img`
    height: 1.2rem;
    width: auto;
    padding-left: 12px;
    transition: all 0.30s linear;
    &:hover {
        opacity: 0.8;
    }
`

const Social = () => {
    return (
        <React.Fragment>
            <a href="https://de-de.facebook.com/ZieglersBackstube/" rel="noopener" target="_blank">
                <SocialIcon src={Facebook} alt="Ziegler`s Facebook" />
            </a>
            <a style={{ display: "none" }} href="https://de-de.facebook.com/ZieglersBackstube/" rel="noopener" target="_blank">
                <SocialIcon src={Instagram} alt="Ziegler`s Instagram"/>
            </a>
        </React.Fragment>
    )
}

export default Social;