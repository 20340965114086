import styled, { css } from "styled-components"
import { mediaQueries, ThemeColors } from "./Themes"

export const ContainerBoxed = styled.div < { padding?: string }> `
    display: grid;
    grid-template-columns: ${({ padding }) => padding ? padding : "0"}% auto ${({ padding }) => padding ? padding : "0"}%;

    ${mediaQueries("Laptop")`
        grid-template-columns: 10% auto 10%;
    `};

    ${mediaQueries("Desktop")`
            grid-template-columns: 22% auto 22%;
    `};
`

export interface GridItemProps {
    gridColumnStart?: number,
    gridColumnEnd?: number,
    gridRowStart?: number,
    gridRowEnd?: number,
    justifySelf?: string;
}

export const GridItem = styled.div<GridItemProps>`
    grid-column-start: ${({ gridColumnStart }) => gridColumnStart};
    grid-column-end: ${({ gridColumnEnd }) => gridColumnEnd};
    grid-row-start: ${({ gridRowStart }) => gridRowStart};
    grid-row-end:  ${({ gridRowEnd }) => gridRowEnd};
    justify-self: ${({ justifySelf }) => justifySelf};
`

export const GridItemOverflowHidden = styled(GridItem)`
    overflow: hidden;
    background-color: transparent;
`

export const Column2 = styled.div`
    grid-column: 2;
`

export const Spacing = styled.div<{ size: number }>`
    margin-top: ${({ size }) => size}rem;
`

export const ContainerBoxedRelative = styled(ContainerBoxed)`
    position: relative;
`