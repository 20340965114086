import React from 'react'
import styled, { css } from 'styled-components'
import { ThemeColors } from './Themes'

interface HamburgerProps {
    isOpen: boolean
}

const Wrapper = styled.div<{ isOpen: boolean }>`
    width: 32px;
    height: 20px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;

    span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: ${({ theme }) => theme.colors.fonts.body};
        border-radius: 3px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
        &:nth-child(1) {
            top: 0;
            -webkit-transform-origin: left center;
            -moz-transform-origin: left center;
            -o-transform-origin: left center;
            transform-origin: left center;
            ${({ isOpen }) => isOpen && css`
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
                top: -3px;
                left: 8px;
            `}

        }
        &:nth-child(2) {
            top: 10px;
            -webkit-transform-origin: left center;
            -moz-transform-origin: left center;
            -o-transform-origin: left center;
            transform-origin: left center;
            ${({ isOpen }) => isOpen && css`
                width: 0%;
                opacity: 0;
            `}
        }
        &:nth-child(3) {
            top: 20px;
            -webkit-transform-origin: left center;
            -moz-transform-origin: left center;
            -o-transform-origin: left center;
            transform-origin: left center;
            ${({ isOpen }) => isOpen && css`
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
                top: 20px;
                left: 8px;
            `}
        }
    }
`

const Hamburger = (props: HamburgerProps) => {
    const { isOpen } = props;

    return (
        <Wrapper isOpen={isOpen}>
            <span />
            <span />
            <span />
        </Wrapper>
    )
}

export default Hamburger