import * as React from 'react'
import styled, { css } from 'styled-components'

import Logo from './Logo'
import Social from '../Social/Social'
import Mittagtisch from './../../images/Mittagstisch.svg'
import { Link } from 'gatsby'
import { mediaQueries } from './Themes'
import { StoryblokBase } from '../../types'
import NavItem, { NavItemProps } from './NavItem'

const NavContainer = styled.div`
    ${mediaQueries("Mobile")`
        display: none;
    `};

    ${mediaQueries("Tablet")`
        display: grid;
        grid-template-columns: 25% auto;
        grid-template-rows: 1fr auto;
        align-items: center;
    `};

`

const NavItemGrid = styled.div`
    display: grid;
    align-items: center;
    justify-items: center;
    transition: 0.3s;

    ${mediaQueries("Tablet")`
        grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
        grid-row: 1;
        grid-column: 2;
        height: unset;
        display: grid;
    `};
`

const MittagstischLinkStyle = styled(Link)`
    img {
        width: 2.5rem;
        transition: all 0.30s linear;
    }
    &:hover {
        img {
            transform: rotate(-10deg);
            }
    }
`

interface NavProps {
    navItems: StoryblokBase[],
    location: Location
}

interface MittagsTischLinkProps {
    onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const MittagsTischLink: React.FC<MittagsTischLinkProps> = ({ onClick }) => (
    <MittagstischLinkStyle onClick={onClick} to="/mittagstisch">
        <img src={Mittagtisch} alt="Ziegler´s Mittagstisch. Immer frisch gekocht." />
    </MittagstischLinkStyle>
)

const Nav = (props: NavProps) => {

    return (
        <NavContainer>
            <div>
                <Logo height="50" />
            </div>
            <NavItemGrid>
                {props.navItems && props.navItems.map((blok) =>
                    <NavItem
                        key={blok._uid}
                        blok={blok as NavItemProps}
                        location={props.location} />)}
                <div>
                    <Social />
                </div>
                <MittagsTischLink />
            </NavItemGrid>
        </NavContainer>
    )
}

export default Nav;
