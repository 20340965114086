import React from 'react'
import styled from 'styled-components'

import { StoryblokBase, StoryblokComponentProps } from '../../types'

import Card, { GridCard } from '../Layout/Card'
import { GridItemOverflowHidden } from '../Layout/Grid'
import { mediaQueries } from '../Layout/Themes'

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 1rem;
`

interface ThreeImageGridProps extends StoryblokBase {
    cards: GridCard[]
}

const ThreeImageGrid = (props: StoryblokComponentProps<ThreeImageGridProps>) => {
    const { blok } = props;
    return (
        <Grid>
            <GridItemOverflowHidden gridColumnStart={1} gridColumnEnd={2} gridRowStart={1} gridRowEnd={2}>
                <Card card={blok.cards && blok.cards[0]} />
            </GridItemOverflowHidden>
            <GridItemOverflowHidden gridColumnStart={1} gridColumnEnd={2} gridRowStart={2} gridRowEnd={3}>
                <Card card={blok.cards && blok.cards[1]} />
            </GridItemOverflowHidden>
            <GridItemOverflowHidden gridColumnStart={2} gridColumnEnd={3} gridRowStart={1} gridRowEnd={3}>
                <Card card={blok.cards && blok.cards[2]} />
            </GridItemOverflowHidden>
        </Grid>
    )
}

export default ThreeImageGrid