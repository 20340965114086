import * as React from 'react'

import { StoryblokBase, StoryblokComponentProps } from '../types'

interface ComponentNotFoundProps extends StoryblokComponentProps<StoryblokBase> {
}

const ComponentNotFound = (props: ComponentNotFoundProps) => {
  const { blok } = props;

  return (
    blok ?
      <div>
        Component {props.blok.component} is not defined. Add it to components.ts
      </div> :
      <React.Fragment />
  )
}



export default ComponentNotFound