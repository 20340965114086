import React from "react";
import styled from "styled-components";

const Image = styled.img`
  display: block;
  width: 100%;
  max-width: 400px;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
`;

export const Img = ({ src }: { src: string }) => {
  return <Image src={src} />;
};
