import * as React from 'react'
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import { StoryblokLink } from '../../types';

interface Button {
    text: string;
    link: StoryblokLink;
    className?: string;
}

const ButtonTextStyle = css`
    display: block;
    box-sizing: border-box;
    text-transform: uppercase;
    padding: .175rem 2rem;
    font-family: ${({ theme }) => theme.font.headings.family};
    font-size: 1rem;
    font-weight: ${({ theme }) => theme.font.headings.weight.medium};
    color: ${({ theme }) => theme.colors.buttons.outline.border};
    text-decoration: none;
`
export const OutlineButtonStyle = styled.button`
    padding: 0;
    background: transparent;
    border: 1px solid ${({ theme }) => theme.colors.buttons.outline.border};
    border-radius: 8px;
    transition: all 0.30s linear;
    text-align: center;
    outline: none;
    cursor: pointer;
    ${ButtonTextStyle}
    &:hover {
        background: ${({ theme }) => theme.colors.buttons.outline.border};
        color: ${({ theme }) => theme.colors.buttons.outline.textHover};
        a {
            color: ${({ theme }) => theme.colors.buttons.outline.textHover};
        }
    }

    a {
        ${ButtonTextStyle}
    }
`

const OutlineButton: React.FC<Button> = (props: Button) => {
    return (
        <OutlineButtonStyle className={props.className}>
            <Link to={"/" + props.link.cached_url}>
                {props.text}
            </Link>
        </OutlineButtonStyle>
    )
}

export default OutlineButton