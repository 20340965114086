import * as React from 'react'
import { useRef } from 'react'
import styled from 'styled-components'

import { StoryblokBase, StoryblokComponentProps, } from '../../types'
import { Close } from '../Icons'
import { useState } from 'react'
import { RichTextProps } from '../RichText/RichText'
import Components from '../Components'
import { ApplicationButton } from '../Job/JobListItem'
import { Spacing } from '../Layout/Grid'

interface AccordionProps extends StoryblokBase {
    title: string,
    subTitle: string,
    uppercaseTitle: boolean,
    content: RichTextProps[],
    borderTop?: boolean,
    applicationLink?: boolean
}

const Wrapper = styled.div<{ borderTop: boolean }>`
    display: flex;
    flex-direction: column;
    border-top: ${({ borderTop, theme }) => borderTop ? theme.name === "light" ? "1px solid " + theme.colors.colors.Black : "1px solid " + theme.colors.colors.Beige : "none"};
    border-bottom: 1px solid ${({ theme }) => theme.name === "light" ? theme.colors.colors.Black : theme.colors.colors.Beige};
`

const Title = styled.h2<{ isUppercase: boolean }>`
    text-transform: ${({ isUppercase }) => isUppercase ? "uppercase" : "none"};
    margin: 0;
    margin-right: 0.5rem;
    text-align: left;
`

const SubTitle = styled.span`
    padding-left: 12px;
    font-family:  ${({ theme }) => theme.font.headings.family};
    font-weight:  ${({ theme }) => theme.font.headings.weight.regular};
    color: ${({ theme }) => theme.colors.fonts.body};
    font-size: 1rem;
`

const AccordionButton = styled.button`
    display: flex;
    align-items: center;
    background: ${({ theme }) => theme.colors.backgrounds.body};
    border: 0;
    padding: 12px;
    outline: none;
    cursor: pointer;
`

const Content = styled.div`
    background-color: ${({ theme }) => theme.colors.backgrounds.body};
    overflow-y: auto;
    transition: max-height 0.6s ease;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    margin: 0;
    &::-webkit-scrollbar {
        display: none;
    }
    p {
        padding: 12px;
        padding-top: 0;
        margin: 0;
    }
`

const Icon = styled(Close) <{ setRotate: boolean }>`
    margin-left: auto;
    padding: 0.5rem;
    transition: transform 0.6s ease;
    transform: ${({ setRotate }) => setRotate ? "rotate(0deg)" : "rotate(45deg)"};
    cursor: pointer;
    fill: ${({ theme }) => theme.colors.fonts.body};
`

const Accordion = (props: StoryblokComponentProps<AccordionProps>) => {
    const { blok } = props;
    const [setActive, setActiveState] = useState("");
    const [setHeight, setHeightState] = useState("0px");
    const [setRotate, setRotateState] = useState(false);
    const content = useRef(null);

    const toggleAccordion = () => {
        setActiveState(setActive === "" ? "active" : "");
        setRotateState(setRotate === false ? true : false);
        setHeightState(setActive === "active" ? "0px" : `${content.current.scrollHeight}px`)
    }

    return (
        <Wrapper borderTop={blok.borderTop}>
            <AccordionButton onClick={toggleAccordion}>
                <Title isUppercase={blok.uppercaseTitle}>{blok.title}</Title>
                {
                    blok.subTitle && <SubTitle>{blok.subTitle}</SubTitle>
                }
                <Icon width={24} height={24} setRotate={setRotate} />
            </AccordionButton>
            <Content style={{ maxHeight: `${setHeight}` }} ref={content}>
                {blok.content.map((element) => React.createElement(Components(element.component), { key: element._uid, blok: element }))}
                {blok.applicationLink &&
                    <React.Fragment>
                        <ApplicationButton style={{ margin: '1rem' }} to={'/karriere/aktuelle-stellen/online-bewerbung?job=' + blok.title + '&desc=' + blok.subTitle + '&type=' + 'Ausbildung'}> Jetzt bewerben </ApplicationButton>
                        <Spacing size={4} />
                    </React.Fragment>
                }
            </Content>
        </Wrapper>
    )
}

export default Accordion