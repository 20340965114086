import * as React from 'react'
import { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components'

import { mediaQueries } from '../Layout/Themes'

import SignageLight from './../../images/SignageLight.png'
import SignageDark from './../../images/SignageDark.png'

const SignageImageStyle = styled.img`
    position: sticky;
    z-index: 0;
    top: 200px;
    right:-10px;
    height: 480px;
    width: auto;

    ${mediaQueries("Mobile")`
        display: none;
    `};

    ${mediaQueries("Tablet")`
        display: inline-block;
    `};
`

const SignageImage = () => {
    const themeContext = useContext(ThemeContext);

    return (
        <SignageImageStyle src={themeContext.name === 'dark' ? SignageDark : SignageLight} alt="Ziegler`s Backstube"/>
    )
}

export default SignageImage