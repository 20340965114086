import * as React from 'react'

import { StoryblokBase, StoryblokComponentProps, } from '../../types'
import { Spacing as SpacingStyle } from '../../components/Layout/Grid'

interface SpacingProps extends StoryblokBase {
    size: number
}

const Spacing = (props: StoryblokComponentProps<SpacingProps>) => {
    const { blok } = props;

    return (
        <SpacingStyle size={blok.size} />
    )
}

export default Spacing