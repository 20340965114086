import * as React from 'react'
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import { StoryblokBase, StoryblokComponentProps, StoryblokImage, StoryblokMetaFields } from '../../types';
import Components from '../Components';
import { Content } from '../ContainerBoxed/ContainerBoxed';
import BackButton from '../Layout/BackButton';
import { ContainerBoxedRelative, Spacing } from '../Layout/Grid';
import { mediaQueries } from '../Layout/Themes';
import SignageImage from '../SignageImage/SignageImage';
import { SliderProps } from '../Slider/MainSlider';
import JobListItem from './JobListItem';

interface CurrentJob extends StoryblokBase {
    description: string,
}

export interface JobProps extends StoryblokBase {
    visible: string,
    title: string,
    area?: string,
    subTitle: string,
    previewImage: StoryblokImage,
    slider: SliderProps,
    twoColumnGrid: StoryblokBase[],
    currentJobs: CurrentJob[],
    seo?: StoryblokMetaFields
}

const Grid = styled.div`
    display: grid;
    margin-top: 48px;
    grid-column: 2;
    z-index: 1;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 6rem;
    grid-auto-flow: row dense;
    padding: 0 12px;
    align-items: center;
`

const SliderContainer = styled.div`
    white-space: pre-wrap;
    display: block;
    overflow: hidden;
    ${mediaQueries("Mobile")`
        grid-column-start: 1;
        grid-column-end: 3;
    `};

    ${mediaQueries("Tablet")`
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row: 2;
    `};
`

const TitleContainer = styled.div`
    ${mediaQueries("Mobile")`
        grid-column-start: 1;
        grid-column-end: 3;
    `};

    ${mediaQueries("Tablet")`
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row: 2;
    `};

    h1 {
        margin-bottom: 0;
    }
    h3 {
        margin-top: 0;
    }
`


const Job = (props: StoryblokComponentProps<JobProps>) => {
    const { blok } = props;
    const slider = blok.slider && blok.slider[0];
    const { seo } = blok;
    return (
        <ContainerBoxedRelative>
            <Helmet>
                {seo?.title && <title>{seo.title}</title>}
                {seo?.description && <meta name="description" content={seo.description} />}
            </Helmet>
            <Content>
                <BackButton />
                <Grid>
                    <SliderContainer>
                        {slider && React.createElement(Components(slider.component), { key: slider._uid, blok: slider, location: props.location })}
                    </SliderContainer>
                    <TitleContainer>
                        <h1>{blok.title}</h1>
                        <h3>{blok.subTitle}</h3>
                    </TitleContainer>
                </Grid>
                <Spacing size={4} />
                {props.blok.twoColumnGrid.map((blok) => React.createElement(Components(blok.component), { key: blok._uid, blok: blok, location: props.location }))}
                <Spacing size={6} />
                {(blok.currentJobs && blok.currentJobs.length > 0) && <h3 style={{ textAlign: "center" }}>Aktuelle Stellen:</h3>}
                <Spacing size={2} />
                {
                    blok.currentJobs?.map((job, index) => <JobListItem
                        key={job._uid}
                        title={blok.title}
                        subTitle={blok.subTitle}
                        description={job.description}
                        borderTop={index === 0 ? true : false} />)
                }
                {blok.currentJobs && <Spacing size={12} />}
            </Content>
            <SignageImage />
        </ContainerBoxedRelative>
    )
}

export default Job;