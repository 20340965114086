import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import { StoryblokImage } from '../../types'
import { TransformImage } from '../Helper'
import { LocationStar } from '../Icons'

interface LocationCardProps {
    image: StoryblokImage,
    title: string,
    subTitle?: string,
    link: string
    showIcon: boolean,
    className?: string
}

export const CardImage = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
    vertical-align: bottom;
`

const Card = styled.div`
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    transition: transform 0.3s;
    &:hover {
        transform: translate(0px, -6px);
    }

    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
`

const TitleSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    background-color: ${({ theme }) => theme.colors.colors.White};

    span {
        color: ${({ theme }) => theme.colors.colors.Grey3};
        font-family: ${({ theme }) => theme.font.headings.family};
        font-weight: ${({ theme }) => theme.font.headings.weight.medium};
        font-size: 1.15rem;
        line-height: 1rem;
        text-transform: uppercase;
    }
`

const Icon = styled(LocationStar)`
    align-self: flex-end center;
`

const StyledLink = styled(Link)`
    display: block;
    text-decoration: none;
`

const LocationCard = (props: LocationCardProps) => {
    return (
        <Card className={props.className && props.className}>
            {
                props.link ?
                    <StyledLink to={props.link}>
                        <CardImage src={TransformImage(props.image.filename, "300x0", "filters:quality(80)")} alt={props.image.alt} />
                        <TitleSection><span>{props.title}</span> {props.showIcon && <Icon height={20} color={"#c8b482"} />}
                            {
                                props.subTitle && <span><br /> {props.subTitle}</span>
                            }
                        </TitleSection>
                    </StyledLink> :
                    <React.Fragment>
                        <CardImage src={TransformImage(props.image.filename, "300x0", "filters:quality(80)")} alt={props.image.alt} />
                        <TitleSection><span>{props.title}</span> {props.showIcon && <Icon height={20} color={"#c8b482"} />}</TitleSection>
                    </React.Fragment>
            }

        </Card>
    )
}

export default LocationCard