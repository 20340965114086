const React = require("react");
const Layout = require("./src/components/Layout/Layout").default;
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.wrapPageElement = ({ element, props }) => {
    return (
        <Layout {...{ ...props.pageContext }}>
            {element}
        </Layout>
    )
}