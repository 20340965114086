import * as React from 'react'

import { StoryblokBase, StoryblokComponentProps } from '../../types';
import Components from '../Components';

interface CommonReferenceProps extends StoryblokBase {
    reference: {
        content
    }
}

const CommonReference = (props: StoryblokComponentProps<CommonReferenceProps>) => {
    const { blok } = props;
    return (
        blok.reference &&
        <React.Fragment>
            {React.createElement(Components(blok.reference.content?.component), { key: blok._uid, blok: blok.reference.content, location: props.location })}
        </React.Fragment>

    )
}

export default CommonReference