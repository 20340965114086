import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import Recaptcha from 'react-google-recaptcha'

import styled, { css } from 'styled-components'
import BackButton from '../Layout/BackButton'
import { Spacing } from '../Layout/Grid'
import { mediaQueries } from '../Layout/Themes'


const Grid = styled.div`
    display: grid;
    padding: 8px;

    a {
        color: ${({ theme }) => theme.name === 'light' ? '#000' : '#fff'};
        font-weight: ${({ theme }) => theme.font.body.weight.semiBold};
        &:hover {
            opacity: 0.7;
        }
    }

`

const HeadingSection = styled.div`
    grid-column-start: 1;
    grid-column-end: 3;
    white-space: pre-wrap;
`

const Input = styled.input`
    display: block;
    border: 0;
    background: ${({ theme }) => theme.colors.backgrounds.body};
    color: ${({ theme }) => theme.colors.fonts.body};
    font-family: ${({ theme }) => theme.font.body.family};
    font-weight: ${({ theme }) => theme.font.body.weight.regular};
    border-bottom: 1px solid ${({ theme }) => theme.colors.fonts.body};
    line-height: 2rem;
    font-size: 1rem;
    width: 100%;
    margin-bottom: 1rem;
`

const Form = styled.form`
    position: relative;
    z-index: 5;
    display:grid;
    grid-column-start: 1;
    grid-column-end: 3;

    ${mediaQueries("Mobile")`
        grid-template-columns: 1fr;
        grid-row-gap: 2rem;
    `};

    ${mediaQueries("Tablet")`
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 4rem;
        padding: 2rem;
    `};
`

const FormLeft = styled.div`
    ${mediaQueries("Mobile")`
        grid-column-start: 1;
        grid-column-end: 2;
    `};

    ${mediaQueries("Tablet")`
        grid-column-start: 1;
        grid-column-end: 2;
    `};
`

const FormRight = styled.div`
    ${mediaQueries("Mobile")`
        grid-column-start: 1;
        grid-column-end: 2;
    `};

    ${mediaQueries("Tablet")`
        grid-column-start: 2;
        grid-column-end: 3;
    `};
`

const FormText = styled.p`
    font-size: 0.8rem;
`

const HiddenInput = styled.input`
    height: 0px;
    width: 0px;
    overflow: hidden;
    opacity: 0;
`

const ButtonStyle = css`
    border-radius: 4px;
    font-size: 1rem;
    padding: 4px 36px;
    text-transform: uppercase;
    transition: all 0.3s;
    font-family: ${({ theme }) => theme.font.body.family};
    font-weight: ${({ theme }) => theme.font.body.weight.semiBold};
    cursor: pointer;
    margin-top: 4px;
    &:hover {
        opacity: 0.8;
    }
`

const UploadButton = styled.button`
    ${ButtonStyle}

    background: ${({ theme }) => theme.colors.backgrounds.body};
    color: ${({ theme }) => theme.colors.fonts.body};
    border: 1px solid ${({ theme }) => theme.colors.fonts.body};

    ${mediaQueries("Mobile")`
        display: block;
    `};

    ${mediaQueries("Tablet")`
        float:right;
    `};
`

const SubmitButton = styled.button`
    ${ButtonStyle}
    background: ${({ theme }) => theme.name === 'light' ? '#000' : '#fff'};
    color: ${({ theme }) => theme.name === 'light' ? '#fff' : '#000'};
    border: 1px solid ${({ theme }) => theme.name === 'light' ? '#000' : '#fff'};
    margin-top: 24px;
    &[disabled] {
        opacity: 0.2;
        cursor: unset;
    }

    ${mediaQueries("Mobile")`
        justify-self: center;
    `};

    ${mediaQueries("Tablet")`
        justify-self: start;
    `};
`

const UploadContainer = styled.div`
    margin-top: 18px;
    border-radius: 8px;
    padding: 18px;
    background: #f7f4ec;
`

const Overlay = styled.div`
    display: grid;
    align-items: center;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #f7f4ec;
    border-radius: 4px;
    opacity: .96;
    transition: 0.3s all;
    text-align: center;
`

const IsSendingText = styled.h3`
    @keyframes blink {
        0% {
        opacity: .2;
        }
        20% {
        opacity: 1;
        }
        100% {
        opacity: .2;
        }
    }

    span {
        animation-name: blink;
        animation-duration: 1.4s;
        animation-iteration-count: infinite;
        animation-fill-mode: both;
    }

    span:nth-child(2) {
        animation-delay: .2s;
    }

    span:nth-child(3) {
        animation-delay: .4s;
    }
`


const ApplicationForm = () => {
    const uploadInput = useRef(null);
    const [files, setFiles] = useState<FileList>(undefined)
    const [text, setText] = useState("")
    const [isSuccess, setIsSuccess] = useState(false)
    const [isSending, setIsSending] = useState(false)
    const [isCaptcha, setIsCaptcha] = useState(false)

    useEffect(() => {
        if (location.search) {
            const job = getParameterByName('job')
            const description = getParameterByName('desc')
            const type = getParameterByName('type')
            setText(`Für eine ${(type === "undefined" || null) ? 'Stelle' : type} als ${job} ${description}`)
        } else {
            setText("Bewerbe dich initiativ. Einfach Lebenslauf und Anschreiben mitschicken. Wir melden uns bei dir.")
        }
    }, [])

    const openUploadInput = () => {
        uploadInput.current.click();
    }

    const handleOnSubmit = (e) => {
        setIsSending(true)
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);

        postData('https://getform.io/f/7f5209e4-2f7a-4e25-b8a0-16ad8bef238b', formData).then(() => {
            setIsSending(false)
            setIsSuccess(true)
            form.reset();
        }).catch((err) => {
            setIsSending(false)
            console.error(err)
        })
    }

    const captchaOnChange = (value) => {
        if (value) {
            setIsCaptcha(true)
        } else {
            setIsCaptcha(false)
        }
    }

    return (
        <Grid>
            <BackButton />
            <HeadingSection>
                <h2>Meine Online-Bewerbung</h2>
                <p>{text}</p>
            </HeadingSection>
            <Form onSubmit={handleOnSubmit} encType="multipart/form-data">
                {
                    (isSending || isSuccess) &&
                    <Overlay>
                        {
                            isSuccess &&
                            <div>
                                <h2>Vielen Dank für deine Bewerbung</h2>
                                <p>Wir melden uns in Kürze bei dir.</p>
                            </div>
                        }
                        {
                            isSending &&
                            <IsSendingText>Bewerbung wird abgeschickt <span>.</span><span>.</span><span>.</span></IsSendingText>
                        }
                    </Overlay>
                }
                <FormLeft>
                    <HiddenInput type="text" name="description" id="description" defaultValue={text} />
                    <Input type="text" name="name" id="name" placeholder="Name" required />
                    <Input type="email" name="email" id="email" placeholder="E-Mail" required />
                    <label>
                        Lebenslauf & Anschreiben
                    </label>
                    <HiddenInput ref={uploadInput} type="file" name="files[]" onChange={(element) => setFiles(element.target.files)} required multiple />
                    <UploadButton type="button" onClick={openUploadInput}>Anhängen</UploadButton>

                    {files &&
                        <UploadContainer>
                            {Array.from(files).map((item, key) => <span key={item.lastModified + item.size + key}><strong>{item.name}</strong> ({formatBytes(item.size)}) <br /></span>)}
                        </UploadContainer>
                    }
                </FormLeft>
                <FormRight>
                    <FormText>
                        Mit Absenden des Formulars stimmen Sie zu, dass Ihre Angaben aus dem Formular zur Beantwortung Ihrer Anfrage erhoben und verarbeitet werden. Die Daten werden nach abgeschlossener Bearbeitung Ihrer Anfrage gelöscht. Hinweis: Sie können Ihre Einwilligung jederzeit für die Zukunft per E-Mail an <strong>info@zieglers-backstube.de</strong> widerrufen.
                                    Detaillierte Informationen zum Umgang mit Nutzerdaten finden Sie in unserer <a href="/datenschutz">Datenschutzerklärung</a>.
                    </FormText>
                    <Recaptcha
                        sitekey={process.env.GATSBY_RECAPTCHA_KEY}
                        onChange={captchaOnChange}
                    />
                    <SubmitButton disabled={isCaptcha === false || files?.length === 0} type="submit">Bewerbung senden</SubmitButton>
                </FormRight>
            </Form>
            <Spacing size={8} />
        </Grid>
    )
}

export default ApplicationForm


function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

async function postData(url, data: FormData) {
    const response = await fetch(url, {
        method: 'POST',
        body: data
    });
    return response;
}