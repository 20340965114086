import * as React from 'react'
import styled from 'styled-components'

import { StoryblokComponentProps } from '../../types/StoryblokComponentProps'
import { StoryblokBase } from '../../types/StoryblokBase'

interface UnderOverlineTextProps extends StoryblokBase {
  text: string;
  size?: number;
}

export const UnderOverlineStyle = styled.span<{ size?: number }>`
  font-family: ${({ theme }) => theme.font.headings.family};
  font-weight: ${({ theme }) => theme.font.headings.weight.regular};
  font-size: ${({ size }) => size ? size + "rem" : "1.25rem"};
  border-top: 1px solid ${({ theme }) => theme.colors.fonts.body};
  border-bottom: 1px solid ${({ theme }) => theme.colors.fonts.body};
  padding-top: -4px;
  padding-left: 8px;
  padding-right: 8px;
  text-transform: uppercase;

  svg {
    fill: ${({ theme }) => theme.colors.fonts.body};
  }
`

const UnderOverlineText = (props: StoryblokComponentProps<UnderOverlineTextProps>) => {
  const { blok } = props;
  return (
    <UnderOverlineStyle size={blok.size}>{blok.text}</UnderOverlineStyle>
  )
}

export default UnderOverlineText
