import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import { StoryblokImage, StoryblokLink } from '../../types'
import { TransformImage } from '../Helper'
import { UnderOverlineStyle } from '../UnderOverlineText/UnderOverlineText'
import { mediaQueries } from './Themes'

export interface GridCard {
    image: StoryblokImage,
    link: StoryblokLink,
    text?: string,
    isBorder?: boolean
}

export interface CardProps {
    card: GridCard,
    imagePosition?: string
}

const CardWrapper = styled.div <{ isBorder: boolean }>`
    display: grid;
    position: relative;
    box-sizing: border-box;
    height: 100%;
    border: ${({ isBorder, theme }) => isBorder ? "1px solid" + theme.colors.colors.Beige : "none"};

    a {
        text-decoration: none;
    }
`

const CardImage = styled.img<{ objectPosition?: string }>`
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: ${({ objectPosition }) => objectPosition ? objectPosition : "center"};
    vertical-align: bottom;
    transition: transform .3s ease;
    &:hover {
        transform: scale(1.05);
    }
`

const Text = styled(UnderOverlineStyle)`

    color: #fff;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;

    ${mediaQueries("Mobile")`
        position: absolute;
        bottom: 12px;
        left: 12px;
        font-size: 0.8rem;
    `};

    ${mediaQueries("Tablet")`
        position: absolute;
        bottom: 24px;
        left: 24px;
        font-size: 1.25rem;
    `};
`

const Card: React.FC<CardProps> = ({ imagePosition, card }) => {
    return (
        card ?
            <CardWrapper isBorder={card.isBorder}>
                {
                    card.link.cached_url !== "" ?
                        <Link to={"/" + card.link.cached_url}>
                            <CardImage objectPosition={imagePosition} src={TransformImage(card.image.filename, "", "filters:quality(80)")} alt={card.image.filename} />
                            {card.text && <Text>{card.text}</Text>}
                        </Link> : <div><CardImage objectPosition={imagePosition} src={TransformImage(card.image.filename, "", "filters:quality(80)")} alt={card.image.filename} />
                        </div>
                }
            </CardWrapper> :
            <div><p>Please upload an image</p></div>
    )
}

export default Card