import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import { StoryblokBase, StoryblokComponentProps, StoryblokImage } from '../../types'
import { mediaQueries } from '../Layout/Themes'
import { Spacing } from '../Layout/Grid'
import { getStoryblokClient } from '../storyBlokClient'
import Card, { CardProps } from './../Card/Card'

interface ProductFilterProps extends StoryblokBase {
    type: string
}

const Grid = styled.div`
    display: grid;
    grid-gap: 12px;
    margin: 12px;

    ${mediaQueries("Mobile")`
        grid-template-columns: repeat(2, 1fr);
    `};

    ${mediaQueries("Tablet")`
        grid-template-columns: repeat(4, 1fr);
    `};
`

const FilterMenuWrapper = styled.div`
    max-width: 100%;
    text-align: center;
    position: relative;
    z-index: 20;
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    ::-webkit-scrollbar {
        display: none;
    }
`
const MenuItemSelectedStyle = css`
    border-top: ${({ theme }) => "1px solid " + theme.colors.swipeMenu.selected};
    border-bottom: ${({ theme }) => "1px solid " + theme.colors.swipeMenu.selected};
        color: ${({ theme }) => theme.colors.swipeMenu.selected};
    &:hover {
            color: ${({ theme }) => theme.colors.swipeMenu.selected};
            cursor: default;
    }
`

const MenuItemStyle = css<{ active: boolean }>`
    font-size: 1.1rem;
    font-weight: ${({ theme }) => theme.font.headings.weight.regular};
    font-family:    ${({ theme }) => theme.font.headings.family};
    text-transform: uppercase;
    display: inline-block;
    vertical-align: middle;
    padding: 0 0.25rem;
    margin: 0 1rem;
    cursor: pointer;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.swipeMenu.color};
    transition: all 0.30s linear;
    border-top: ${({ theme }) => "1px solid transparent"};
    border-bottom: ${({ theme }) => "1px solid transparent"};
    &:hover {
            color: ${({ theme }) => theme.colors.swipeMenu.hover};
    }
    ${({ active }) => active && MenuItemSelectedStyle}
`

const MenuItem = styled.a`
    ${MenuItemStyle}
`;


const ProductFilter = (props: StoryblokComponentProps<ProductFilterProps>) => {
    const { blok } = props
    const [products, setProducts] = useState<StoryblokBase[]>([])
    const [filter, setFilter] = useState("alle")

    const storiesPath = 'produkte/' + blok.type

    useEffect(() => {
        const client = getStoryblokClient()
        client.getAll('cdn/stories', {
            starts_with: storiesPath
        }).then((stories) => {
            setProducts(stories)
        }).catch((error) => {
            console.error(error)
        })
    }, [])


    const onlyProductsList = products.filter((product) => product.content.component !== "page");

    const productList: CardProps[] = [];
    onlyProductsList.map((element) => {
        let slug = element.slug
        let type = element.content.type ? element.content.type : 'alle'
        element.content.product.map((childElement) => {
            let product = childElement
            const newProduct: CardProps = {
                image: product.previewImage,
                title: product.title,
                subTitle: product.subTitle,
                link: slug,
                type
            }

            productList.push(newProduct)
        })
    })

    // fill the filter with items
    const filterMenuItems = ["alle"]
    productList.forEach(element => {
        if (filterMenuItems.indexOf(element.type) === -1) {
            filterMenuItems.push(element.type)
        }
    });

    let filteredList = []
    if (filter != "alle") {
        filteredList = productList.filter((product) => product.type === filter);
    } else {
        filteredList = [...productList]
    }


    return (
        <div>
            {filterMenuItems.length > 1 &&
                <FilterMenuWrapper>
                    {filterMenuItems.map((item, index) =>
                        <MenuItem key={"filterMenuItem_" + index} active={item === filter} onClick={() => setFilter(item)}>{item}</MenuItem>
                    )}
                </FilterMenuWrapper>
            }
            <Spacing size={2} />
            <Grid>
                {
                    filteredList.map((product, index) =>
                        <Card key={"product_" + index} image={product.image} link={product.link} title={product.title} subTitle={product.subTitle} />
                    )
                }
            </Grid>
        </div>
    )

}

export default ProductFilter