import * as React from 'react'
import styled, { css } from 'styled-components';

import { StoryblokBase, StoryblokComponentProps } from '../../types';
import { mediaQueries } from '../Layout/Themes';
import { getStoryblokClient } from '../storyBlokClient';

export interface RichTextProps extends StoryblokBase {
    richText: StoryblokBase,
    align?: string,
    responsivePadding?: boolean
}

interface StoryblokComponentRichTextProps extends StoryblokComponentProps<RichTextProps> {
    location: Location
}

const responsivePaddingStyle = css`
    ${mediaQueries("Mobile")`
        padding: 0 0.5rem;
    `}

    ${mediaQueries("Tablet")`
        margin: 0 6rem;
    `}

    ${mediaQueries("Desktop")`
        margin: 0 10rem;
    `}
`

const RichTextContainer = styled.div<{ align?: string, responsivePadding?: boolean }>`
    padding: 0 0.5rem;
    text-align: ${({ align }) => align && align};
    ${({ responsivePadding }) => responsivePadding && responsivePaddingStyle}
    a {
        color: ${({ theme }) => theme.colors.fonts.body};
        font-weight: 600;
        transition: 0.3s all;
        &:hover {
            opacity: 0.7;
        }
    }

    ${mediaQueries("Mobile")`
        h1 {
            font-size: 2.5rem;
        }
    `}

    ${mediaQueries("Tablet")`
        h1 {
            font-size: 3rem;
        }
    `}
`

const RichText = (props: StoryblokComponentRichTextProps) => {
    const storyBlok = getStoryblokClient();

    storyBlok.setComponentResolver((component, blok) => {
        switch (component) {
            case 'underOverlineText':
                return `<h3 class="under-overline-text">${blok.text}</h3>`
                break;
        }
    })

    return (
        <RichTextContainer align={props?.blok?.align} responsivePadding={props?.blok?.responsivePadding} dangerouslySetInnerHTML={{ __html: storyBlok.richTextResolver.render(props.blok.richText) }} />
    )
}

export default RichText