import * as React from 'react'
import styled from 'styled-components'
import { ThemeProvider } from 'styled-components'
import { useState, useEffect } from 'react'
import useScrollPosition from '@react-hook/window-scroll'
import { Helmet } from 'react-helmet'

import GlobalStyles from './GlobalStyles'
import { lightTheme, darkTheme, mediaQueries } from './Themes'
import Nav from './Nav'
import { ContainerBoxed } from './Grid'
import Footer from './Footer'
import { StoryblokBase } from '../../types'
import useDarkMode from './useDarkMode'
import ThemeToggler from './ThemeToggler'
import MobileNav from './MobileNav'
import config from '../../../gatsby-config'

import Favicon from '../../images/favicons/favicon.ico'
import Favicon32 from '../../images/favicons/favicon-32.png'
import Favicon128 from '../../images/favicons/favicon-128.png'
import Favicon152 from '../../images/favicons/favicon-152.png'
import Favicon180 from '../../images/favicons/favicon-180.png'
import Favicon192 from '../../images/favicons/favicon-192.png'
import Favicon196 from '../../images/favicons/favicon-192.png'

const Wrapper = styled.div`
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;
    transition: all 0.30s linear;
    background: ${({ theme }) => theme.colors.backgrounds.body};
    background-repeat: repeat;
    width: 100%;
`

const Header = styled.header`
    background: ${({ theme }) => theme.colors.backgrounds.header};
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;

    ${mediaQueries("Mobile")`
        display: none;
    `};

    ${mediaQueries("Tablet")`
        display: block;
    `};
`

const ThemeTogglerContainer = styled.div<{ showToggler: boolean }>`
  align-self: center;
  justify-self: end;
  margin-right: 24px;
  transition: all 0.30s linear;
  display: ${(props) => props.showToggler ? "block" : "none"};
  .rotate {
    transform: rotate(90deg);
  }
`

type LayoutProps = {
    global: any;
    children: any;
}

const Layout: React.FC<LayoutProps> = ({ global, children }) => {
    let globalItems = undefined;

    if (global) {
        globalItems = JSON.parse(global?.content);
    }

    const [theme, themeToggler] = useDarkMode();

    const [isScrolled, setIsScrolled] = useState(false);
    const scrollY = useScrollPosition(60 /*fps*/);

    useEffect(() => {
        // check if it is scrolled
        scrollY >= 80 ? setIsScrolled(true) : setIsScrolled(false);
    }, [scrollY]);
    return (
        <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
            <Helmet
                defaultTitle={config.siteMetadata.title}
                titleTemplate={config.siteMetadata.title + " | %s"}>
                <meta charSet="utf-8" />
                <html lang="de" />
                <meta name="description" content={config.siteMetadata.description} />
                <script async defer data-domain="zieglers-backstube.de" src="https://plausible.io/js/plausible.js"></script>

                <link rel="shortcut icon" href={Favicon} type="image/x-icon" />

                <link rel="icon" href={Favicon32} sizes="32x32" />
                <link rel="icon" href={Favicon128} sizes="128x128" />
                <link rel="icon" href={Favicon192} sizes="192x192" />

                <link rel="shortcut icon" href={Favicon196} sizes="196x196" />

                <link rel="apple-touch-icon" href={Favicon152} sizes="152x152" />
                <link rel="apple-touch-icon" href={Favicon180} sizes="180x180" />
            </Helmet>
            <GlobalStyles />
            {
                globalItems &&
                <MobileNav navItems={globalItems.header[0].navItems as StoryblokBase[]} location={children.location}>
                    <div><span>Hell</span> <ThemeToggler theme={theme} toggleTheme={themeToggler} /> <span>Dunkel</span> </div>
                </MobileNav>
            }
            <Wrapper>
                <Header>
                    <ContainerBoxed>
                        <div></div>
                        {
                            globalItems &&
                            <Nav navItems={globalItems.header[0].navItems as StoryblokBase[]} location={children.location} />
                        }
                        <ThemeTogglerContainer showToggler={isScrolled}>
                            <ThemeToggler theme={theme} toggleTheme={themeToggler} />
                        </ThemeTogglerContainer>
                    </ContainerBoxed>
                </Header>
                <div style={{ marginTop: "84px" }}>
                    {children}
                </div>
                {
                    globalItems &&
                    <Footer navItems={globalItems.footer[0].navItems && globalItems.footer[0].navItems} />
                }
            </Wrapper>
        </ThemeProvider >
    )
}

export default Layout