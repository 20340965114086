import React from "react";
import { useApplicationCtx } from "./ApplicationCtx";
import { Button } from "./Button";
import { Img } from "./Img";
import { data } from "./data";

export const WhatElse = () => {
  const { goTo, answers } = useApplicationCtx();

  const position = answers.get("Stelle");
  const img = data.find((x) => x.position === position)?.image2;

  return (
    <>
      <h2>Das bieten wir dir noch:</h2>

      <Img src={img} />

      <ul>
        <li>
          💶 Dich erwartet ein guter und leistungsgerechter Lohn und stets
          pünktliche Bezahlung bei einem fairen und verantwortungsbewussten
          Arbeitgeber
        </li>
        <li>⭐️ Zusätzlich erhältst du Urlaubs- und Weihnachtsgeld</li>
        <li>
          📈 Vermögenswirksame Leistungen und betriebliche Altersvorsorge. Damit
          du dich darauf verlassen kannst , dass dein Lebensstandard auch im
          hohen Alter gesichert ist!
        </li>
        <li>
          🏖 32 Tage Urlaub im Jahr, damit du die Auszeit bekommst, die du dir
          verdient hast!
        </li>
        <li>
          💰 Deine Mehrarbeit lohnt sich! Alle Überstunden werden bei uns
          vergütet und ausbezahlt
        </li>
        <li>💳 30% Mitarbeiterrabatt auf unser komplettes Sortiment</li>
        <li>
          🚲 Jobrad Leasing: Für deine Gesundheit, weniger Tankkosten und mehr
          Geld für dich am Ende des Monats, hast du die Möglichkeit hochwertige
          Fahrräder und E-Bikes kostengünstig zu leasen
        </li>
      </ul>

      <Button onClick={() => goTo("p4-your-day")}>
        <span>Das gefällt mir!</span>
        <br />
        <span>Wie sieht mein Tag bei euch aus?</span>
      </Button>
    </>
  );
};
