import React from "react";
import { useApplicationCtx } from "./ApplicationCtx";
import { Button } from "./Button";
import { Img } from "./Img";

import Image from "./assets/Unbenannt-3.jpg";

export const WhoWeAre = () => {
  const { goTo } = useApplicationCtx();

  return (
    <>
      <h2>Wer wir sind</h2>

      <Img src={Image} />

      <p>
        Wir sind ein moderner Familienbetrieb der vor fast 100 Jahren gegründet
        wurde und stehen mit unseren 25 Bäckerei-Cafés und über 400 Mitarbeitern
        in der Region Freudenstadt- Baiersbronn- Horb- Nagold- Jettingen- Oberndorf
        für traditionelles Back- und Genusshandwerk. Ein wichtiger Baustein
        unseres Erfolges sind seit jeher unsere Mitarbeiter!
      </p>

      <p>
        Bist du vielleicht sogar unser neuer Bäcker/Konditor/Verkäufer (m/w/d)? Dann bewirb dich
        hier in 2 Minuten.
      </p>

      <p>Herzlich willkommen bei uns!</p>

      <Button onClick={() => goTo("entry")}>Jetzt bewerben</Button>
    </>
  );
};
