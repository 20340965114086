import styled from "styled-components";
import { PageKeys, useApplicationCtx } from "./ApplicationCtx";
import React from "react";
import { Button } from "./Button";

const NavStyle = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

type Props = {
  nextPage?: PageKeys;
  previousPage?: PageKeys;
  nextDisabled?: boolean;
};

export const NavButtons = ({ nextPage, previousPage, nextDisabled }: Props) => {
  const { goTo } = useApplicationCtx();

  if (nextPage == null && previousPage == null) return <></>;
  return (
    <NavStyle>
      {previousPage != null && (
        <Button onClick={() => goTo(previousPage)} style={{ gridColumn: 1 }}>
          ⬅️ Zurück
        </Button>
      )}

      {nextPage != null && (
        <Button
          onClick={() => goTo(nextPage)}
          style={{ gridColumn: 2 }}
          disabled={nextDisabled}
        >
          ➡️ Weiter
        </Button>
      )}
    </NavStyle>
  );
};
