import React from 'react'

import { IconProps } from './index'

export const LocationHeadline = (props: IconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill={props.color ? props.color : "#000"} className={props.className} height={props.height ? props.height : 27.12} viewBox="0 0 18.38 27.12" width={props.width ? props.width : 18.38} >
            <path d="M9.3,27.12,7.93,26.06C8,26,16.65,14.67,16.65,9.19a7.46,7.46,0,0,0-14.92,0c0,4.35,6.37,13.34,6.43,13.43l-1.41,1C6.48,23.24,0,14.09,0,9.19a9.19,9.19,0,0,1,18.38,0C18.38,15.25,9.67,26.64,9.3,27.12Z" /><path d="M9.19,13A3.77,3.77,0,1,1,13,9.19,3.77,3.77,0,0,1,9.19,13Zm0-5.8a2,2,0,1,0,2,2A2,2,0,0,0,9.19,7.16Z" />
        </svg >
    )
}