import Bäcker1 from "./assets/Bäckerbild 1.jpg";
import Bäcker2 from "./assets/Bäckerbild 2.jpg";
import Bäcker3 from "./assets/Bäckerbild 3.jpg";

import Verkäufer1 from "./assets/Bild Seite 1 - Verkäuferin.jpg";
import Verkäufer2 from "./assets/Bild Seite 2 - Verkäuferin.jpg";
import Verkäufer3 from "./assets/Bild Seite 3 - Verkäuferin.jpg";

import Konditor1 from "./assets/Konditor Seite 1.jpg";
import Konditor2 from "./assets/Konditor Seite 2.jpg";
import Konditor3 from "./assets/Konditor Seite 3.jpg";

type Data = {
  /** Job position */
  position: string;
  benefits: string[];
  yourDay: string[];
  qualifications: { label: string; exit?: boolean }[];
  image1: any;
  image2: any;
  image3: any;
}[];

export const data: Data = [
  {
    position: "Bäcker",
    benefits: [
      "💡 Du darfst dich bei uns einbringen!",
      "📄 Unbefristeter Arbeitsvertrag",
      "Wir bieten dir einen sicheren und krisenfesten Arbeitsplatz",
      "👩‍🍳 👨‍🍳 Kollegiale und familiäre Arbeitsatmosphäre: Unsere Mitarbeiter liegen uns am Herzen! Bei uns herrscht ein harmonisches Miteinander auf allen Ebenen und flache Hierarchien.",
      "💪 Wir leben das traditionelle Back- und Genusshandwerk",
      "Wir verwenden nur hochwertige Rohstoffe aus der Region",
      "Handwerkliches Knowhow",
      "Unterstützung durch computergesteuerte Maschinen",
      "😎 Du wirst von gut ausgebildeten Kollegen und Kolleginnen erwartet, die ordentlich was auf dem Kasten haben und mit dir an einem Strang ziehen",
    ],
    yourDay: [
      "🍞 Du bist verantwortlich für die Herstellung von unseren beliebten Broten und Brötchen nach unseren traditionellen Ziegler Rezepturen.",
      "🍩 Außerdem fertigst du Fein- und Plundergebäck nach traditioneller Handwerkskunst an in Zusammenarbeit mit unserem Team.",
      "🌡 Du übernimmst den Dienst am Backofen und gewährleistest die perfekte Qualität unserer leckeren Backwaren",
    ],
    qualifications: [
      { label: "Abgeschlossene Berufsausbildung als Bäcker" },
      { label: "Abgeschlossene Berufsausbildung als Bäckermeister" },
      { label: "Mehrjährige Berufserfahrung als Bäcker" },
      { label: "Eine ähnliche Qualifikation" },
      { label: "Hochmotiviert das Bäckerhandwerk zu erlernen" },
      { label: "Keine vergleichbare Qualifikation", exit: true },
    ],
    image1: Bäcker1,
    image2: Bäcker2,
    image3: Bäcker3,
  },
  {
    position: "Konditor",
    benefits: [
      "💡Du darfst dich bei uns einbringen!",
      "📄 Unbefristeter Arbeitsvertrag",
      "Wir bieten dir einen sicheren und krisenfesten Arbeitsplatz",
      "👩‍🍳 👨‍🍳 Kollegiale und familiäre Arbeitsatmosphäre: Unsere Mitarbeiter liegen uns am Herzen! Bei uns herrscht ein harmonisches Miteinander auf allen Ebenen und flache Hierarchien.",
      "💪 Wir leben das traditionelle Back- und Genusshandwerk",
      "Wir verwenden nur hochwertige Rohstoffe aus der Region",
      "Handwerkliches Knowhow",
      "Unterstützung durch computergesteuerte Maschinen",
      "😎 Du wirst von gut ausgebildeten Kollegen und Kolleginnen erwartet, die ordentlich was auf dem Kasten haben und mit dir an einem Strang ziehen",
    ],
    yourDay: [
      "🍞 Du bist verantwortlich für die Herstellung von unseren beliebten Kuchen & Torten  nach unseren traditionellen Ziegler Rezepturen.",
      "🍩 Außerdem fertigst du weitere süße Leckereien nach traditioneller Handwerkskunst an in Zusammenarbeit mit unserem Team.",
    ],
    qualifications: [
      { label: "Abgeschlossene Berufsausbildung als Konditor" },
      { label: "Abgeschlossene Berufsausbildung als Konditormeister" },
      { label: "Mehrjährige Berufserfahrung als Konditor" },
      { label: "Eine ähnliche Qualifikation" },
      { label: "Hochmotiviert das Konditorhandwerk zu erlernen" },
      { label: "Keine vergleichbare Qualifikation", exit: true },
    ],
    image1: Konditor1,
    image2: Konditor2,
    image3: Konditor3,
  },
  {
    position: "Verkäufer",
    benefits: [
      "💡Du darfst dich bei uns einbringen!",
      "💰 50% Sonn- und Feiertagszuschläge",
      "Wir bieten dir einen sicheren und krisenfesten Arbeitsplatz",
      "👩‍🍳 👨‍🍳 Kollegiale und familiäre Arbeitsatmosphäre: Unsere Mitarbeiter liegen uns am Herzen! Bei uns herrscht ein harmonisches Miteinander auf allen Ebenen und flache Hierarchien.",
      "💪 Wir leben das traditionelle Back- und Genusshandwerk",
      "Wir verwenden nur hochwertige Rohstoffe aus der Region",
      "Handwerkliches Knowhow",
      "Unterstützung durch computergesteuerte Maschinen",
      "😎 Du wirst von gut ausgebildeten Kollegen und Kolleginnen erwartet, die ordentlich was auf dem Kasten haben und mit dir an einem Strang ziehen",
    ],
    yourDay: [
      "🍞 Du bist verantwortlich für den Verkauf und die Warenpräsentation von unseren beliebten Ziegler Produkten.",
      "Du backst frische Brötchen und Brezeln und gewährleistest so die perfekte Qualität unserer ofenfrischen Backwaren",
      "Du kümmerst dich um die Herstellung unserer beliebten Snacks und belegten Brötchen und machst so unserer hungrigen Kunden glücklich",
    ],
    qualifications: [
      {
        label:
          "Abgeschlossene Berufsausbildung als Verkäufer (branchenunabhängig)",
      },
      {
        label: "Mehrjährige Berufserfahrung als Verkäufer (branchenunabhängig)",
      },
      { label: "Eine ähnliche Qualifikation" },
      { label: "Hochmotiviert die Kunst des Verkaufens zu erlernen" },
      { label: "Erfahrung als Verkaufsleiter/ Filialleiter" },
      { label: "Keine vergleichbare Qualifikation", exit: true },
    ],
    image1: Verkäufer1,
    image2: Verkäufer2,
    image3: Verkäufer3,
  },
];
