import React from "react";
import { useApplicationCtx } from "./ApplicationCtx";
import { NavButtons } from "./NavButtons";
import { RadioButton, RadioGroup } from "./RadioGroup";

export const Experience = () => {
  const { answers } = useApplicationCtx();

  return (
    <>
      <h2>Frage 2 von 4</h2>

      <p>
        Wie viel Jahre Berufserfahrung konntest du in diesem Bereich bereits
        sammeln?
      </p>

      <RadioGroup groupKey="Berufserfahrung">
        <RadioButton label="1-3 Jahre" />
        <RadioButton label="3-5 Jahre" />
        <RadioButton label="Über 5 Jahre" />
        <RadioButton label="Bisher noch keine" />
      </RadioGroup>

      <NavButtons
        previousPage="q1-qualifications"
        nextPage={"q3-locality"}
        nextDisabled={answers.get("Berufserfahrung") == null}
      />
    </>
  );
};
