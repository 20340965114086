import React, { useState } from 'react'
import styled from 'styled-components'

async function post(url) {
    const response = await fetch(url, {
        method: 'POST',
        mode: 'no-cors'
    });
    return response;
}

const Container = styled.div`
    padding: 12px;
`

const Deploy = () => {
    const [text, setText] = useState("Deployment nur starten wenn succesfull dran steht. Falls failed dran steht, bitte bei codeunity melden.");

    const onClick = () => {
        post('https://api.netlify.com/build_hooks/5fd0da541146c819ca1d21ad').then(() => {
            setText("deployment getriggert. Bitte warten.")
        }).catch((err) => {
            setText(err + " Bitte codeunity Bescheid geben")
        })
    }
    return (
        <Container>
            <img src="https://api.netlify.com/api/v1/badges/7597ab7d-2577-4998-b6c6-abecda29bf6d/deploy-status" />
            <p>{text}</p>
            <button onClick={onClick}>
                Deploy
            </button>
        </Container>
    )
}

export default Deploy